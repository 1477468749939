import React from 'react';
import { Alert } from '@material-ui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Severity } from '../models/Severity';
import SpacerV from './common/SpacerV';
import { TText } from './common/TText';

interface ConfirmationDialogProps {
  title: string;
  text: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmationDialog = ({ title, text, open, onConfirm, onCancel }: ConfirmationDialogProps) => (
  <Dialog open={open} onClose={onCancel}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{text}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onConfirm} autoFocus color="primary">
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);
