import React, { useEffect, useState } from 'react';
import { createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import type { ApplePay as Apple, PaymentMethod, PaymentRequestOptions, Payments } from '@square/web-payments-sdk-types';
import { isEmpty } from 'lodash';
import { Apple as AppleIcon } from '@material-ui/icons';

// @ts-ignore
import browserInfo from '@smartbear/browser-info';

browserInfo.detect();

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      margin: '0px 10px',
    },
    iconFilled: {
      color: theme.palette.primary.main,
    },
    button: {
      display: 'flex',
      backgroundColor: '#000',
      padding: 11,
      borderRadius: 16,
      color: theme.palette.common.white,
      textAlign: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
  });

interface Props extends WithStyles<typeof styles> {
  payments: Payments;
  initiatePayment: (paymentMethod: PaymentMethod) => Promise<void>;
  getPaymentInfo: () => PaymentRequestOptions;
}

function ApplePay(props: Props) {
  const ap = {} as Apple;
  const isSafari = browserInfo.name === 'Safari';

  const { classes } = props;
  const [applePay, setApplePay] = useState(ap);

  const initializeApplePay = async () => {
    const paymentRequest = props.payments.paymentRequest(props.getPaymentInfo());
    const aPay = await props.payments.applePay(paymentRequest);
    setApplePay(aPay);
    // Note: Apple pay does not need to be "attached"
  };

  // Handle Square payment methods initialization and re-attachment
  useEffect(() => {
    if (!isEmpty(props.payments)) {
      if (isEmpty(applePay) && isSafari) {
        initializeApplePay();
      }
    } else if (!isEmpty(applePay)) {
      applePay.destroy();
      setApplePay(ap);
    }
  }, [props.payments]);

  return (
    <div>
      {isSafari && !isEmpty(applePay) && (
        <div
          id="apple-pay"
          onClick={() => {
            props.initiatePayment(applePay).then();
          }}
          className={classes.button}
        >
          <AppleIcon className={classes.icon} />
          <Typography variant="h5" style={{ color: '#fff' }}>
            Pay with ApplePay
          </Typography>
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(ApplePay);
