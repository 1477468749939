import React from 'react';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import SpacerV from 'components/common/SpacerV';
import Description from 'components/common/Description';
import Item from 'components/common/Item';
import { appStore } from '../../stores';
import PoolInfo from '../../models/PoolInfo';
import MainLayout from '../../layout/hospitality/MainLayout';

const Department = observer(() => {
  const onClickPool = async (pool: PoolInfo) => {
    await appStore.addSelectedPool(pool);

    appStore.goToNextScreen();
  };

  return (
    <MainLayout disableSteps>
      <Grid container alignItems="center" direction="column" spacing={4}>
        <Grid item xs={12} style={{ width: '100%' }}>
          <Description value="Choose the department you want to tip" />
          <SpacerV />
          {appStore.payload?.pools !== undefined &&
            appStore.payload.pools.map((pool, index) => (
              <Item key={pool.name + index} label={pool.name} onClick={() => onClickPool(pool)} />
            ))}
          <SpacerV />
        </Grid>
      </Grid>
    </MainLayout>
  );
});

export default Department;
