import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core';
import type {
  Payments,
  GooglePay as Google,
  PaymentRequestOptions,
  PaymentMethod,
} from '@square/web-payments-sdk-types';
import { isEmpty } from 'lodash';

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      margin: '0px 10px',
    },
    iconFilled: {
      color: theme.palette.primary.main,
    },
    button: {
      borderRadius: 20,
    },
  });

interface Props extends WithStyles<typeof styles> {
  payments: Payments;
  initiatePayment: (paymentMethod: PaymentMethod) => Promise<void>;
  getPaymentInfo: () => PaymentRequestOptions;
}

function GooglePay(props: Props) {
  const { classes } = props;
  const gp = {} as Google;

  const [googlePay, setGooglePay] = useState(gp);

  const attachGooglePay = (gPay?: any) => {
    const googlePayObject = gPay || googlePay;

    if (!isEmpty(googlePayObject)) {
      googlePayObject.attach('#google-pay', {
        buttonColor: 'black',
        buttonSizeMode: 'fill',
        buttonType: 'long',
      });
    }
  };

  const initializeGooglePay = async () => {
    if (!isEmpty(props.payments)) {
      const paymentRequest = props.payments.paymentRequest(props.getPaymentInfo());

      const gPay = await props.payments.googlePay(paymentRequest);
      setGooglePay(gPay);
      attachGooglePay(gPay);
    }
  };

  // Handle Square payment methods initialization and re-attachment
  useEffect(() => {
    if (!isEmpty(props.payments)) {
      if (isEmpty(googlePay)) {
        initializeGooglePay();
      }
    } else if (!isEmpty(googlePay)) {
      googlePay.destroy();
      setGooglePay(gp);
    }
  }, [props.payments]);

  return (
    <div
      id="google-pay"
      className={classes.button}
      onClick={() => {
        props.initiatePayment(googlePay).then();
      }}
    />
  );
}

export default withStyles(styles)(GooglePay);
