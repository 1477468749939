/* eslint-disable consistent-return */
import axios from 'axios';
import PoolTipWebRequest from 'models/PoolTipWebRequest';
import TipRequest from 'models/TipRequest';
import PaymentWebResponse from 'models/PaymentWebResponse';
import { PaymentResponse } from 'models/PaymentResponse';
import AppStore, { appStore, TipIntentParams } from 'stores/AppStore';
import getFingerprint from 'utils';
import config from 'config';

const api = {
  v1: axios.create({ baseURL: `${config.api.tips}/v1` }),
  v2: axios.create({ baseURL: `${config.api.tips}/v2` }),
};

api.v1.interceptors.request.use((_config) => {
  _config.headers['X-Fingerprint'] = getFingerprint();
  return _config;
});

api.v2.interceptors.request.use((_config) => {
  _config.headers['X-Fingerprint'] = getFingerprint();
  return _config;
});

export class TipsAPI {
  public static async createPoolWebPayment(token: string, tips: PoolTipWebRequest[], cardholder?: string) {
    try {
      const res = await api.v1.post('/payments/pool-web', {
        processor: 'square-web',
        source: token,
        deviceTime: new Date(),
        nameOnCard: cardholder || 'Cardholder',
        tips,
      });

      if (res.status === 200) {
        const payment = res.data.data;
        return payment as PaymentWebResponse;
      }
    } catch (e) {
      throw e;
    }
  }

  public static async createPayment(token: string, tips: TipRequest[], cardholder?: string) {
    try {
      const res = await api.v1.post('/payments/web', {
        processor: 'square-web',
        source: token,
        deviceTime: new Date(),
        nameOnCard: cardholder || 'Cardholder',
        tips,
      });

      if (res.status === 200) {
        return res.data.data as PaymentResponse;
      }
    } catch (e) {
      throw e;
    }
  }

  public static async createQRPayment(
    token: string,
    tipIntentParams: TipIntentParams,
    tips: TipRequest[],
    cardholder?: string,
  ) {
    try {
      const res = await api.v1.post('/payments/qr', {
        processor: 'square-web',
        source: token,
        deviceTime: new Date(),
        nameOnCard: cardholder || 'Cardholder',
        deviceId: tipIntentParams.id,
        intent: tipIntentParams.intent,
        tips,
      });

      if (res.status === 200) {
        return res.data.data as PaymentResponse;
      }
    } catch (e) {
      throw e;
    }
  }

  public static async createPayLaterQRPayment(
    token: string,
    tipIntentParams: TipIntentParams,
    tips: TipRequest[],
    cardholder?: string,
  ) {
    try {
      const res = await api.v1.post('/payments/qr', {
        processor: 'square-web',
        postalCode: 'N/A (digital wallet)',
        source: token,
        nameOnCard: cardholder || 'Cardholder',
        deviceId: tipIntentParams.id,
        scheduledPaymentIntentCode: tipIntentParams.intent,
        tips,
      });

      if (res.status === 200) {
        return res.data.data as PaymentResponse;
      }
    } catch (e) {
      throw e;
    }
  }

  public static async calculateFees(tip: string, mode: string) {
    try {
      const res = await api.v1.post('/proposals/calculate-fees', {
        processor: 'square-web',
        amount: tip,
        mode,
        tipCount: 1,
        locationUid: appStore.selectedLocation?.uid || appStore.intentPayload?.locationUid,
      });

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (e) {
      throw e;
    }
  }

  public static async postReview(tipUids: string[], rating: number, comment: string) {
    try {
      const res = await axios.post(`${config.api.tips}/web/v1/reviews`, {
        tipUids,
        rating,
        comment,
      });

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (e) {
      throw e;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  public static async exchangeTipIntentCode(deviceId: string, intent: string, appStore: AppStore) {
    try {
      const res = await api.v1.post('/payments/intent', { deviceId, intent });

      if (res.status === 200) {
        return res.data.data;
      } else {
        appStore.goToNotFoundScreen();
      }
    } catch (e) {
      appStore.goToNotFoundScreen();
      throw e;
    }
  }

  public static async exchangePayLaterIntentCode(
    deviceId: string,
    scheduledPaymentIntentCode: string,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    appStore: AppStore,
  ) {
    try {
      const res = await api.v1.post('/payments/intent', { deviceId, scheduledPaymentIntentCode });

      if (res.status === 200) {
        return res.data.data;
      } else {
        appStore.goToNotFoundScreen();
      }
    } catch (e) {
      appStore.goToNotFoundScreen();
      throw e;
    }
  }
}
