import axios from 'axios';
import UAParser from 'ua-parser-js';

export const isNormalNumber = (str) => {
  const n = Number(str);
  return n !== Infinity && (String(n) === str || `${String(n)}.` === str) && n >= 0;
};

enum LocalStorageItem {
  FINGERPRINT = 'fingerprint',
}

const uaString = navigator.userAgent;
const parser = new UAParser(uaString);

let fingerprint: string | undefined;

async function getIp() {
  let ip;
  try {
    const { data } = await axios.get('https://api.ipify.org/?format=json');
    ip = data.ip;
  } catch (e: any) {
    console.error('Could not obtain IP address');
  }
  return ip;
}

export async function createFingerprint() {
  const os = parser.getOS();
  const device = parser.getDevice();
  const browser = parser.getBrowser();
  const engine = parser.getEngine();
  const ip = await getIp();

  const fingerprintData = {
    ip,
    os: os.name,
    osVersion: os.version,
    vendor: device.vendor,
    browser: browser.name,
    browserVersion: browser.version,
    engine: engine.name,
    engineVersion: engine.version,
    timezoneOffset: new Date().getTimezoneOffset(),
  };

  fingerprint = btoa(JSON.stringify(fingerprintData));
  localStorage.setItem(LocalStorageItem.FINGERPRINT, fingerprint);
  return fingerprint;
}

function getFingerprint(): string | null {
  return localStorage.getItem(LocalStorageItem.FINGERPRINT);
}

export default getFingerprint;
