/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core';
import Card from '@material-ui/core/Card';

const styles = () =>
  createStyles({
    root: {
      boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.12)',
      borderRadius: 16,
      padding: 20,
      width: '100%',
    },
  });

interface Props extends WithStyles<typeof styles> {
  height?: string;
}

const TCard: React.StatelessComponent<Props> = (props) => {
  const { classes, children, height } = props;
  let styles = {};
  if (height) {
    styles = { height };
  }

  return (
    <Card style={styles} className={classes.root}>
      {children}
    </Card>
  );
};

export default withStyles(styles)(TCard);
