/* eslint-disable consistent-return,default-case,import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { alpha, Avatar, Box, Card, CardActionArea, CardContent } from '@material-ui/core';
import MainLayout from 'layout/hospitality/MainLayout';
import SpacerV from 'components/common/SpacerV';
import TTextField from 'components/common/TTextField';
import TButtonPrimary from 'components/common/TButtonPrimary';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import useDimensions from 'react-use-dimensions';
import { isNormalNumber } from '../../utils';
import { TipLevel } from '../../models/TipLevel';
import { appStore } from '../../stores/AppStore';
import { tipStore } from '../../stores/TipStore';
import { customTipText } from './ProposedTipHospitality';
import { TText } from '../../components/common/TText';
import { createMainTheme } from '../../theme';
import { TCircularProgress } from '../../components/common/TCircularProgress';
import AlertPopup from '../../components/AlertPopup';
import { ConfirmationDialog } from '../../components/ConfirmationDialog';

const useStyles = (props) => {
  const theme = createMainTheme(appStore.theme);

  return makeStyles(() => ({
    root: {
      display: 'flex',
      minHeight: '100vh',
      height: '100vh',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    userCard: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      margin: 0,
      padding: 0,
      color: theme.palette.text.primary,
    },
    avatar: {
      width: '100%',
      height: '40vh',
    },
    tipOptionsRow: {
      display: 'flex',
      width: '100%',
      columnGap: 14,
    },
    tipCard: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      '&::before': {
        content: '""',
        display: 'table',
        paddingTop: '100%',
      },
      color: theme.palette.text.primary,
    },
    selectedSquareColor: {
      backgroundColor: alpha(theme.palette.primary.main, 1),
    },
    tipAmountSquare: {
      '&::before': {
        content: '""',
        opacity: 0.1,
      },
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      flex: 1,
    },
    tipAmountText: {
      fontSize: 20,
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
    tipPercentageText: {
      fontSize: 10,
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
  }));
};

interface TipCardProps {
  tipLevel: TipLevel;
  tip: string;
  setTip: (tip: string) => void;
  customTip: string;
  setCustomTip: (tip: string) => void;
  classes: any;
  goToPayment: (tipAmount: string) => void;
}

const TipCard = observer(({ tipLevel, tip, setTip, customTip, setCustomTip, classes, goToPayment }: TipCardProps) => {
  const tipAmount = (() => {
    switch (tipLevel) {
      case TipLevel.LOW:
        return appStore.locationSettings!.low;
      case TipLevel.MEDIUM:
        return appStore.locationSettings!.medium;
      case TipLevel.HIGH:
        return appStore.locationSettings!.high;
      case TipLevel.CUSTOM:
        throw new Error('Cards should display proposed tips');
    }
  })().toString();

  const tipDescription = (() => {
    switch (tipLevel) {
      case TipLevel.LOW:
        return appStore?.locationSettings?.labelLow;
      case TipLevel.MEDIUM:
        return appStore?.locationSettings?.labelMedium;
      case TipLevel.HIGH:
        return appStore?.locationSettings?.labelHigh;
    }
  })();

  const selected = tip === tipAmount;
  const tipUndefined = !tip || tip === '';

  const circleClasses: any[] = [classes.tipAmountSquare];
  if (selected && !customTip) {
    circleClasses.push(classes.selectedSquareColor);
  }

  const onCardClick = () => {
    setTip(tipAmount);
    setCustomTip('');
    goToPayment(tipAmount);
  };

  return (
    <Card className={classes.tipCard}>
      <CardActionArea style={{ width: '100%', height: '100%', flex: 1 }} onClick={onCardClick}>
        <CardContent style={{ width: '100%', height: '100%', padding: 0 }}>
          <div className={clsx(circleClasses)}>
            <TText className={classes.tipAmountText}>${tipAmount}</TText>
            <TText className={classes.tipPercentageText}>{tipDescription}</TText>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
});

const ProposedTipBeauty = observer((styles) => {
  const { userUid } = useParams<{ userUid: string }>();

  const [ref, { width, height }] = useDimensions();
  const classes = useStyles({ height, width })();

  const [proposedTip, setProposedTip] = useState<string>('');
  const [customTip, setCustomTip] = useState<string>('');

  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!userUid) {
      appStore.goToLanding();
    }
  }, [userUid]);

  const userIdx = (appStore.selectedUsers?.length || 1) - 1;

  const goToPayment = async (tipAmount) => {
    await tipStore.addTip(tipAmount);

    appStore.goToNextScreen();
  };

  const buttonDisabled = (!proposedTip || proposedTip === '') && customTip === '';

  const onClickNext = () => {
    if (customTip && customTip !== '' && Number.parseFloat(customTip) >= 100) {
      setConfirmationOpen(true);
    } else {
      goToPayment(customTip);
    }
  };

  const button = (
    <TButtonPrimary disabled={buttonDisabled} fullWidth onPress={onClickNext}>
      Next
    </TButtonPrimary>
  );

  const getName = (user) => {
    let name = user.nickname;
    if (!name) {
      let surnameInitial = '';
      if (user.lastName) {
        surnameInitial = user.lastName?.charAt(0)?.toUpperCase();
      }
      name = `${user.firstName} ${surnameInitial}`;
    }

    return name;
  };

  if (appStore.loadingData) {
    return (
      <Box
        alignItems="center"
        justifyContent="center"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          width: '100vw',
          height: '100vh',
        }}
      >
        <TCircularProgress />
      </Box>
    );
  }

  return (
    <div>
      {proposedTip && (
        <Box
          alignItems="center"
          justifyContent="center"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            width: '100vw',
            height: '100vh',
          }}
        >
          <TCircularProgress />
        </Box>
      )}
      {!proposedTip && (
        <MainLayout button={button}>
          {appStore.selectedUsers && (
            <Box justifyContent="center" alignItems="center">
              <Card style={{ padding: 0 }}>
                <CardContent className={classes.userCard}>
                  <div style={{ flex: 3, width: '100%' }}>
                    <Avatar
                      alt={appStore.selectedUsers[userIdx]!.firstName}
                      src={appStore.selectedUsers![userIdx].avatar}
                      className={classes.avatar}
                      variant="square"
                    />
                  </div>
                  <div
                    style={{ flex: 1, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <TText style={{ fontWeight: 'bold', paddingTop: '1em' }}>
                      {getName(appStore.selectedUsers![userIdx])}
                    </TText>
                  </div>
                </CardContent>
              </Card>

              <SpacerV size="large" />

              <TText>{appStore?.locationSettings?.tipInstructionText || 'Select tip amount'}</TText>
              <SpacerV size="small" />
              <div className={classes.tipOptionsRow}>
                <TipCard
                  tipLevel={TipLevel.LOW}
                  tip={proposedTip}
                  setTip={setProposedTip}
                  customTip={customTip}
                  setCustomTip={setCustomTip}
                  classes={classes}
                  goToPayment={goToPayment}
                />
                <TipCard
                  tipLevel={TipLevel.MEDIUM}
                  tip={proposedTip}
                  setTip={setProposedTip}
                  customTip={customTip}
                  setCustomTip={setCustomTip}
                  classes={classes}
                  goToPayment={goToPayment}
                />
                <TipCard
                  tipLevel={TipLevel.HIGH}
                  tip={proposedTip}
                  setTip={setProposedTip}
                  customTip={customTip}
                  setCustomTip={setCustomTip}
                  classes={classes}
                  goToPayment={goToPayment}
                />
              </div>

              <SpacerV size="large" />
              <TText>{customTipText}</TText>
              <TTextField
                label="Custom Tip"
                type="tel"
                fullWidth
                value={customTip}
                onChange={(e) => {
                  const { value } = e.target;
                  if (isNormalNumber(value) || isEmpty(value)) {
                    setCustomTip(value);

                    if (value !== '') {
                      setProposedTip('');
                    }
                  }
                }}
              />
            </Box>
          )}

          <ConfirmationDialog
            title="Wow, a generous tip"
            text={`Are you sure you wanted to tip $${customTip}?`}
            open={confirmationOpen}
            onConfirm={() => {
              goToPayment(customTip);
            }}
            onCancel={() => {
              setConfirmationOpen(false);
            }}
          />
        </MainLayout>
      )}
    </div>
  );
});

export default ProposedTipBeauty;
