import React from 'react';
import { Typography } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import TCard from './TCard';
import SpacerV from './SpacerV';
import Action from './Action';
import { createMainTheme } from '../../theme';
import { appStore } from '../../stores';

const useStyles = makeStyles(() => {
  const theme = createMainTheme(appStore.theme);

  return {
    root: {},
    outer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    inner: {
      flexGrow: 1,
    },
    icon: {
      fontSize: 16,
      color: theme.palette.primary.main,
    },
  };
});

const Item = observer(({ label, onClick }) => {
  const classes = useStyles();

  const item = (
    <TCard>
      <div className={classes.outer}>
        <div className={classes.inner}>
          <Typography style={{ fontWeight: 600 }}>{label}</Typography>
        </div>
        <ArrowForwardIos color="primary" className={classes.icon} />
      </div>
    </TCard>
  );

  return (
    <div className={classes.root}>
      {onClick ? <Action onAction={onClick}>{item}</Action> : <div>{item}</div>}
      <SpacerV size="small" />
    </div>
  );
});

export default Item;
