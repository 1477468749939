import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { Box, Card, CardActionArea, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import LocationInfo from '../../models/LocationInfo';
import SpacerV from '../../components/common/SpacerV';
import Description from '../../components/common/Description';
import { appStore } from '../../stores';
import AlertPopup from '../../components/AlertPopup';
import MainLayout from '../../layout/hospitality/MainLayout';
import { TText } from '../../components/common/TText';
import { TCircularProgress } from '../../components/common/TCircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    height: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  locationCard: {
    marginBottom: 10,
  },
}));

const Locations = observer(() => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const onClickLocation = async (location: LocationInfo) => {
    setLoading(true);
    try {
      // await appStore.selectLocation(location).then();
      await appStore.selectLocationV2(location).then();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && (
        <Box alignItems="center" justifyContent="center" className={classes.root}>
          <TCircularProgress />
        </Box>
      )}
      {!loading && (
        <MainLayout disableSteps>
          <Grid container alignItems="center" direction="column" spacing={4}>
            <Grid item xs={12} style={{ width: '100%' }}>
              <Description value="Choose a location" />
              <SpacerV />
              {appStore.locations !== undefined &&
                appStore.locations.map((location, index) => (
                  <Card
                    key={location.name + index}
                    onClick={() => onClickLocation(location)}
                    className={classes.locationCard}
                  >
                    <CardActionArea>
                      <CardContent style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <TText style={{ fontWeight: 'bold' }}>
                            {location.city}, {location.state} {location.zip}
                          </TText>
                          <TText>{location.address}</TText>
                        </div>
                        <KeyboardArrowRightIcon color="primary" />
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              {appStore.locations === undefined && appStore.goToLanding() && <></>}
              <SpacerV />
            </Grid>
          </Grid>

          <AlertPopup
            open={appStore.alertMessage.open}
            onClose={appStore.closeSnackbar}
            title={appStore.alertMessage.title}
            text={appStore.alertMessage.text}
            severity={appStore.alertMessage.severity}
          />
        </MainLayout>
      )}
    </div>
  );
});

export default Locations;
