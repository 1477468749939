/* eslint-disable @typescript-eslint/no-shadow,@typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { CreditCard } from '@material-ui/icons';
import type { Card, Payments } from '@square/web-payments-sdk-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    margin: '0px 10px',
  },
  iconFilled: {
    color: theme.palette.primary.main,
  },
  button: {
    color: theme.palette.common.white,
    background: '#000',
    borderRadius: 16,
    padding: 10,
    textTransform: 'none',
    fontSize: 18,
  },
}));

interface Props {
  payments: Payments;
  onLoad: (card: Card) => void;
  setAllValid: (valid: boolean) => void;
}

const SquareCard = ({ payments, onLoad, setAllValid }: Props) => {
  const c = {} as Card;
  const classes = useStyles();

  const enterCreditCard = () => {
    setShowFields(true);
  };

  const [showFields, setShowFields] = useState(false);
  const [squareCard, setSquareCard] = useState(c);
  const [event, setEvent] = useState(undefined);
  const [postalCodeMissing, setPostalCodeMissing] = useState(false);

  const [validFields, setValidFields] = useState({
    cardNumber: false,
    cvv: false,
    expirationDate: false,
    postalCode: true,
  });

  const updateValidity = (field: string, valid: boolean) => {
    const newValidFields = { ...validFields };
    newValidFields[field] = valid;

    setValidFields(newValidFields);
    setAllValid(Object.keys(newValidFields).every((key) => newValidFields[key] === true) && !postalCodeMissing);
  };

  useEffect(() => {
    if (event !== undefined) {
      // @ts-ignore
      updateValidity(event.detail.field, event.detail.currentState.isCompletelyValid);
      // @ts-ignore
      setPostalCodeMissing(event.detail.postalCodeValue === '');
    }
  }, [event]);

  const handleCardEvents = (event: any) => {
    setEvent(event);
  };

  // Attach the Square card to our container and setup event listeners
  const attachCard = (card: any) => {
    // We pass in the card object during initialization, but re-use it from
    // state for normal re-renders
    const cardObject = card || squareCard;
    cardObject.attach('#card-container');

    // Listeners: https://developer.squareup.com/reference/sdks/web/payments/objects/Card#Card.addEventListener
    // cardObject.addEventListener("submit", () =>
    // handlePaymentMethodSubmission(cardObject)
    // )

    cardObject.addEventListener('focusClassAdded', handleCardEvents);
    cardObject.addEventListener('focusClassRemoved', handleCardEvents);
    cardObject.addEventListener('errorClassAdded', handleCardEvents);
    cardObject.addEventListener('errorClassRemoved', handleCardEvents);
    cardObject.addEventListener('cardBrandChanged', handleCardEvents);
    cardObject.addEventListener('postalCodeChanged', handleCardEvents);
  };

  const isEmpty = (obj: object) => {
    if (obj && Object.keys(obj).length === 0) {
      return true;
    }

    return false;
  };

  const initializeSquareCard = async () => {
    if (!isEmpty(payments)) {
      const card = await payments.card();
      setSquareCard(card);
      onLoad(card);
      attachCard(card);
    }
  };

  // Handle Square payment methods initialization and re-attachment
  useEffect(() => {
    if (!isEmpty(payments)) {
      if (isEmpty(squareCard)) {
        initializeSquareCard();
      }
    } else if (!isEmpty(squareCard)) {
      squareCard.destroy();
      setSquareCard(c);
    }
  }, [payments]);

  const styles = { display: 'none' };
  if (showFields) {
    styles.display = 'flex';
  }

  return (
    <div>
      {!showFields && (
        <Button variant="contained" fullWidth onClick={enterCreditCard} className={classes.button}>
          <CreditCard className={classes.icon} />
          Enter Credit Card
        </Button>
      )}
      <div style={styles}>
        <form id="payment-form" style={{ width: '100%' }}>
          <div id="card-container" />
        </form>
      </div>
    </div>
  );
};

export default SquareCard;
