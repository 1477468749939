/* eslint-disable @typescript-eslint/no-use-before-define,no-restricted-syntax */
import PoolInfo from 'models/PoolInfo';

import { makeAutoObservable, toJS } from 'mobx';
import type { PaymentMethod, PaymentRequestOptions } from '@square/web-payments-sdk-types';

import SquarePayments from 'components/square/Payments';
import Payload from 'models/Payload';
import { TippyScreen } from 'models/TippyScreen';
import { TippingMode } from 'models/TippingMode';
import PoolTipWebRequest from 'models/PoolTipWebRequest';
import PaymentWebResponse from 'models/PaymentWebResponse';
import type { Severity } from 'models/Severity';
import { makePersistable } from 'mobx-persist-store';
import { isEmpty } from 'lodash';
import { Group, IStation, Item, ItemType, TippyItems } from 'models/Items';
import { createFingerprint } from 'utils';
import { PaymentStatus } from '../models/PaymentStatus';
import { CodePrefix } from '../models/CodePrefix';
import { CoreAPI } from '../api/CoreAPI';
import { TipsAPI } from '../api/TipsAPI';
import LocationInfo from '../models/LocationInfo';
import { User } from '../models';
import { LocationSettings, ThemeSettings } from '../models/LocationSettings';
import TipRequest from '../models/TipRequest';
import { PaymentResponse } from '../models/PaymentResponse';
import { KioskTip, TipIntentPayload } from '../models/TipIntentPayload';
import { tipStore } from './TipStore';
import config from '../config';

interface TokenResult {
  token: string;
  status: string;
}

export enum IntentType {
  QR,
  PayLaterSMS,
}

export interface TipIntentParams {
  id: string;
  intent: string;
  intentType: IntentType;
}

export default class AppStore {
  alertMessage = {
    title: 'Welcome',
    text: '',
    open: false,
    severity: 'success' as Severity,
  };

  initialized = false;

  code?: string;
  codePrefix?: CodePrefix;

  tipIntentParams?: TipIntentParams;

  payload?: Payload;
  locations?: LocationInfo[];
  flow: TippyScreen[] = [];
  currentScreen?: TippyScreen;
  currentSubrouteIndex?: number;
  customerInstructions = '';

  intentPayload?: TipIntentPayload;
  selectedPools?: PoolInfo[];
  selectedRoomNumbers: string[] = [];
  selectedLastNames: string[] = [];

  selectedLocation?: LocationInfo;
  locationSettings?: LocationSettings;

  users?: User[];
  selectedUsers?: User[];

  preSelectedUserUid?: string;

  poolPaymentResponse?: PaymentWebResponse;
  userPaymentResponse?: PaymentResponse;
  selectedNumberOfNights: number[] = [];

  historySub?: any;
  currentPathname?: string;

  ongoingPayment = false;

  loadingData = false;

  theme?: ThemeSettings;

  items?: Item[];
  filteredItems?: Item[];

  station?: IStation;

  group?: Group;

  uid?: string;

  fingerprint?: string;

  resetValues(preSelectedUserUid?: string) {
    this.station = undefined;
    this.items = undefined;
    this.filteredItems = undefined;
    this.group = undefined;
    this.uid = undefined;

    this.code = undefined;
    this.codePrefix = undefined;

    this.tipIntentParams = undefined;

    this.payload = undefined;
    this.locations = undefined;
    this.flow = [];
    this.currentScreen = undefined;
    this.currentSubrouteIndex = undefined;
    this.customerInstructions = '';

    this.intentPayload = undefined;

    this.selectedPools = undefined;
    this.selectedRoomNumbers = [];
    this.selectedLastNames = [];

    this.selectedLocation = undefined;
    this.locationSettings = undefined;

    this.users = undefined;
    this.selectedUsers = undefined;

    this.preSelectedUserUid = preSelectedUserUid;

    this.poolPaymentResponse = undefined;
    this.userPaymentResponse = undefined;

    this.selectedNumberOfNights = [];

    this.historySub = undefined;
    this.currentPathname = undefined;

    this.ongoingPayment = false;

    this.loadingData = false;

    this.theme = undefined;

    this.fingerprint = undefined;

    document.getElementById('webPayment')?.remove();
  }

  constructor() {
    makeAutoObservable(this);

    createFingerprint();

    makePersistable(this, {
      name: 'CredentialStore',
      properties: [
        'filteredItems',
        'items',
        'uid',
        'group',
        'station',
        'code',
        'codePrefix',
        'payload',
        'flow',
        'currentScreen',
        'currentSubrouteIndex',
        'customerInstructions',
        'intentPayload',
        'selectedPools',
        'selectedRoomNumbers',
        'selectedLastNames',
        'selectedLocation',
        'locationSettings',
        'users',
        'selectedUsers',
        'preSelectedUserUid',
        'poolPaymentResponse',
        'userPaymentResponse',
        'selectedNumberOfNights',
        'historySub',
        'currentPathname',
        'ongoingPayment',
        'loadingData',
        'theme',
        'fingerprint',
      ],

      storage: window.sessionStorage,
    }).then(async () => {
      this.initialized = true;

      // Refresh fingerprint every 6 hours
      const interval = 6 * 3600 * 1000;
      setInterval(() => {
        createFingerprint();
      }, interval);
    });
  }

  async exchangeCode(codePrefix: CodePrefix, url: string) {
    try {
      appStore.codePrefix = codePrefix;
      const payload = (await CoreAPI.exchangeCode(url, appStore)) as Payload;
      appStore.parsePayload(payload);
    } catch (e) {
      console.log(e);
    }
  }

  async exchangeTipIntentCode(deviceId: string, intent: string, intentType: IntentType) {
    try {
      this.tipIntentParams = { id: deviceId, intent, intentType };
      const payload = (await TipsAPI.exchangeTipIntentCode(deviceId, intent, appStore)) as TipIntentPayload;
      await appStore.parseTipIntentPayload(payload);
    } catch (e) {
      console.log(e);
    }
  }

  async exchangePayLaterIntentCode(deviceId: string, scheduledPaymentIntentCode: string, intentType: IntentType) {
    try {
      this.tipIntentParams = { id: deviceId, intent: scheduledPaymentIntentCode, intentType };
      const payload = (await TipsAPI.exchangePayLaterIntentCode(
        deviceId,
        scheduledPaymentIntentCode,
        appStore,
      )) as TipIntentPayload;
      await appStore.parseTipIntentPayload(payload);
    } catch (e) {
      console.log(e);
    }
  }

  async parseTipIntentPayload(payload: TipIntentPayload) {
    this.intentPayload = payload;

    this.users = [];

    try {
      for (const tip of payload.tips) {
        const user = await CoreAPI.getLocationUser(payload.locationUid, tip.userUid);

        this.users.push(user);

        if (!this.intentPayload.total) {
          this.flow.push(TippyScreen.PROPOSED_TIP_INTENT);
        }
      }

      this.selectedUsers = toJS(this.users);

      if (this.intentPayload.total) {
        await this.intentPayload.tips.forEach((tip: KioskTip) => tip.amount && tipStore.addTip(tip));
      }

      this.flow.push(TippyScreen.PAYMENT);

      this.flow.push(TippyScreen.SUCCESS);

      this.goToNextScreen();
    } catch (e) {
      console.log(e);
    }
  }

  parsePayload(payload: Payload) {
    this.payload = payload;
    if (appStore.codePrefix === CodePrefix.USER) {
      appStore.initUserLanding(payload as unknown as User);
    } else if (appStore.codePrefix === CodePrefix.STATION) {
      appStore.initStationLanding(payload);
    } else if (
      (appStore.codePrefix === CodePrefix.LOCATION || appStore.codePrefix === CodePrefix.ACCOUNT) &&
      appStore.preSelectedUserUid
    ) {
      appStore.initBeautyLanding(payload);
    } else if (appStore.codePrefix === CodePrefix.LOCATION) {
      this.initLocationLanding(payload);
    } else if (appStore.codePrefix === CodePrefix.ACCOUNT) {
      this.initBeautyLanding(payload);
    } else if (appStore.codePrefix === CodePrefix.POOL) {
      this.initHospitalityLanding(payload);
    }
  }
  async initLocationLanding(payload: Payload) {
    const location = payload;
    const fullLocation = await CoreAPI.getLocationV3(location.uid, appStore);
    this.items = location.items;
    this.filteredItems = location.items;
    appStore.selectedLocation = fullLocation;
    await appStore.setLocationSettings(location.uid);
    this.flow.push(TippyScreen.LOCATION);
    appStore.goToNextScreen();
  }

  async initStationLanding(payload: Payload) {
    const { items } = payload;
    this.items = items;
    this.filteredItems = items;
    this.station = payload as unknown as IStation;
    const fullLocation = await CoreAPI.getLocationV3(this.station.locationUid, appStore);
    appStore.selectedLocation = fullLocation;

    await appStore.setStationSettings(payload.uid);
    this.payload = { ...this.payload, logo: fullLocation.logo } as Payload;

    appStore.flow.push(TippyScreen.STATION);
    appStore.goToNextScreen();
  }

  async initHospitalityLanding(payload: Payload) {
    // If there is only 1 pool skip department screen
    if (payload.pools!.length > 1) {
      appStore.removeRemainingScreens();
      appStore.flow.push(TippyScreen.DEPARTMENT);
    } else {
      await appStore.addSelectedPool(payload.pools![0]);
      appStore.selectedLocation = { uid: payload.pools![0].locationUid } as LocationInfo;
    }

    appStore.goToNextScreen();
  }

  async preSelectUser(locationUid: string) {
    const user = await CoreAPI.getLocationUser(locationUid, appStore.preSelectedUserUid!);
    const location = await CoreAPI.getLocationV3(locationUid, appStore);

    if (!appStore.selectedLocation) {
      appStore.selectLocationAndUser(location, user).then();
    }
  }

  async initBeautyLanding(payload: Payload) {
    if (appStore.preSelectedUserUid) {
      appStore.preSelectUser(payload.uid).then();
    } else {
      if (payload.locations) {
        appStore.locations = payload.locations;
        if (payload.locations.length > 1) {
          appStore.flow = [];
          appStore.flow.push(TippyScreen.LOCATIONS);
        } else {
          appStore.selectLocationV2(payload.locations![0]);
        }
      } else if (payload.users) {
        this.initLocationLanding(payload);
      }
      appStore.goToNextScreen();
    }
  }

  async selectLocationV2(location: LocationInfo) {
    const fullLocation = await CoreAPI.getLocationV3(location.uid, appStore);

    appStore.selectedLocation = fullLocation;
    await appStore.setLocationSettings(location.uid);

    appStore.removeRemainingScreens();

    this.flow.push(TippyScreen.LOCATION);

    this.items = fullLocation.items;
    this.filteredItems = fullLocation.items;

    appStore.goToNextScreen();
  }

  async initUserLanding(user: User) {
    const fullLocation = await CoreAPI.getLocationV3(user.locationUid, appStore);

    appStore.selectedLocation = fullLocation;
    await appStore.setLocationSettings(user.locationUid);

    appStore.selectedUsers = [user];

    appStore.addUsersFlow([user]);

    appStore.goToNextScreen();
  }

  goToNotFoundScreen() {
    appStore.currentScreen = TippyScreen.NOT_FOUND;
  }

  currentStep() {
    let step = this.flow.indexOf(this.currentScreen!);
    if (this.currentSubrouteIndex) {
      step += this.currentSubrouteIndex - 1;
    }

    return step;
  }

  // TODO: Remove this function when we are sure we want to remove stepper from the app
  // currentStepV2() {
  //   let step = 1;
  //   if (this.currentScreenEquals([TippyScreen.LOCATIONS, TippyScreen.STATION, TippyScreen.GROUP])) {
  //     step = 1;
  //   } else if (
  //     this.currentScreenEquals([
  //       TippyScreen.PROPOSED_TIP_BEAUTY,
  //       TippyScreen.PROPOSED_TIP_HOSPITALITY,
  //       TippyScreen.REVIEW,
  //       TippyScreen.DEPARTMENT,
  //       TippyScreen.PAYMENT,
  //     ])
  //   ) {
  //     step = 2;
  //   } else if (this.currentScreenEquals([TippyScreen.SUCCESS])) {
  //     step = 3;
  //   }

  //   return step - 1;
  // }

  currentScreenEquals(currentScreens: TippyScreen[]) {
    return !!currentScreens.find((screen: TippyScreen) => screen === this.currentScreen);
  }

  codePrefixEquals(codePrefixes: CodePrefix | CodePrefix[]) {
    if (!Array.isArray(codePrefixes)) {
      return this.codePrefix === codePrefixes;
    }
    return !!codePrefixes.find((prefix: CodePrefix) => prefix === this.codePrefix);
  }

  goToPrevScreen() {
    this.preSelectedUserUid = undefined;

    if (this.currentScreen === TippyScreen.PAYMENT) {
      document.getElementById('webPayment')?.remove();
    }

    this.currentScreen = this.flow[this.currentStep() - 1];

    if (this.currentScreenEquals([TippyScreen.STATION, TippyScreen.GROUP, TippyScreen.LOCATION])) {
      this.selectedUsers = undefined;
      this.selectedPools = undefined;
      if (this.currentScreen === TippyScreen.STATION) {
        this.uid = undefined;
        this.group = undefined;
        this.items = [...this.station!.items];
        this.filteredItems = [...this.station!.items];
        this.flow = [TippyScreen.STATION];
      } else if (this.currentScreen === TippyScreen.LOCATION && this.flow.includes(TippyScreen.LOCATIONS)) {
        this.flow = [TippyScreen.LOCATIONS, TippyScreen.LOCATION];
      } else if (this.currentScreen === TippyScreen.LOCATION) {
        this.flow = [TippyScreen.LOCATION];
      } else if (this.currentScreen === TippyScreen.GROUP) {
        this.flow = [TippyScreen.STATION, TippyScreen.GROUP];
      }
    }

    if (this.currentScreen === TippyScreen.PROPOSED_TIP_HOSPITALITY) {
      tipStore.removeLastTip().then();
    }

    if (this.currentScreen === TippyScreen.PROPOSED_TIP_INTENT) {
      if (this.currentSubrouteIndex && this.currentSubrouteIndex > 1) {
        this.currentSubrouteIndex -= 1;
      } else {
        this.currentSubrouteIndex = this.selectedUsers?.length;
      }

      tipStore.removeLastTip().then();
    } else {
      this.currentSubrouteIndex = undefined;
    }

    if (
      this.currentScreen === TippyScreen.PROPOSED_TIP_BEAUTY ||
      this.currentScreen === TippyScreen.PROPOSED_TIP_HOSPITALITY
    ) {
      tipStore.removeLastTip().then();
    }
  }

  goToNextScreen() {
    appStore.loadingData = true;
    const nextScreen = this.flow[this.currentStep() + 1];

    if (nextScreen !== undefined) {
      this.currentScreen = nextScreen;

      if (this.currentScreen === TippyScreen.PROPOSED_TIP_INTENT) {
        if (this.currentSubrouteIndex === undefined) {
          this.currentSubrouteIndex = 1;
        } else {
          this.currentSubrouteIndex += 1;
        }
      } else {
        this.currentSubrouteIndex = undefined;
      }
    }
    appStore.loadingData = false;
  }

  goToLanding() {
    appStore.flow = [];
    appStore.currentScreen = TippyScreen.LANDING;
  }

  async selectPool(pool: PoolInfo) {
    appStore.removeRemainingScreens();

    appStore.selectedPools = [pool];

    if (pool.customerInstructions && !isEmpty(pool.customerInstructions)) {
      this.customerInstructions = pool.customerInstructions;
    } else {
      this.customerInstructions = 'In order to successfully process this tip, we need the following information';
    }

    await this.addPoolsFlow(pool);
  }

  async addSelectedPool(pool: PoolInfo) {
    appStore.removeRemainingScreens();

    let pools: PoolInfo[] = [];
    if (appStore.selectedPools !== undefined) {
      pools = appStore.selectedPools!;
    }
    appStore.selectedPools = [...pools, pool];

    if (pool.customerInstructions && !isEmpty(pool.customerInstructions)) {
      this.customerInstructions = pool.customerInstructions;
    } else {
      this.customerInstructions = 'In order to successfully process this tip, we need the following information';
    }

    await this.addPoolsFlow(pool);
  }

  private payloadEndpointByPrefix() {
    return {
      L: `${config.api.core}/v3/web/locations`,
      P: `${config.api.core}/v2/web/pools`,
      S: `${config.api.core}/v2/web/stations`,
      A: `${config.api.core}/v1/web/accounts`,
      U: `${config.api.core}/v1/web/users`,
    };
  }

  private async setLocationSettings(locationUid) {
    appStore.locationSettings = await CoreAPI.getLocationSettings(locationUid);
    appStore.theme = appStore.locationSettings?.theme;
  }

  private async setStationSettings(locationUid) {
    appStore.locationSettings = await CoreAPI.getStationSettings(locationUid);
    appStore.theme = appStore.locationSettings?.theme;
  }

  async selectLocation(location: LocationInfo) {
    const fullLocation = await CoreAPI.getLocationV3(location.uid, appStore);

    appStore.selectedLocation = fullLocation;
    await appStore.setLocationSettings(location.uid);

    appStore.removeRemainingScreens();

    if (fullLocation?.users !== undefined) {
      appStore.addUsersFlow(fullLocation.users);
    } else if (fullLocation?.pools !== undefined) {
      try {
        const codePrefix = CodePrefix.LOCATION;
        const endpoint = appStore.payloadEndpointByPrefix()[codePrefix];
        const tipUrl = `${endpoint}/${fullLocation.uid}`;
        appStore.exchangeCode(codePrefix, tipUrl).then();
      } catch (e) {
        console.log(e);
      }
    }

    appStore.goToNextScreen();
  }

  async selectUser(user: User) {
    appStore.selectedUsers = [user];
    appStore.goToNextScreen();
  }

  async addSelectedUser(user: User) {
    appStore.selectedUsers?.push(user);
    appStore.goToNextScreen();
  }

  removeSelectedUser(userIdx: number) {
    appStore.selectedUsers!.splice(userIdx, 1);
    tipStore.removeTipAt(userIdx).then();
  }

  removeSelectedPool(poolIdx: number) {
    appStore.selectedPools!.splice(poolIdx, 1);
    tipStore.removeTipAt(poolIdx).then();
  }

  async selectUserAndAddFlow(user: User) {
    if (this.codePrefixEquals([CodePrefix.STATION, CodePrefix.LOCATION, CodePrefix.ACCOUNT])) {
      this.addUsersFlow([user]);
    }

    appStore.selectedUsers = [user];
    appStore.goToNextScreen();
  }

  async selectGroup(group: Group) {
    this.flow.push(TippyScreen.GROUP);
    appStore.uid = group.uid;
    this.items = group.items;
    this.filteredItems = group.items;
    appStore.group = group;
    appStore.goToNextScreen();
  }

  async selectLocationAndUser(location: LocationInfo, user: User) {
    const fullLocationPromise = CoreAPI.getLocationV3(location.uid, appStore);
    const locationSettingsPromise = CoreAPI.getLocationSettings(location.uid);

    Promise.all([fullLocationPromise, locationSettingsPromise]).then(([fullLocation, locationSettings]) => {
      appStore.locationSettings = locationSettings;
      appStore.theme = appStore.locationSettings?.theme;

      appStore.selectedLocation = fullLocation;
      appStore.selectedUsers = [user];

      appStore.removeRemainingScreens();

      this.flow.push(TippyScreen.PROPOSED_TIP_BEAUTY);
      this.flow.push(TippyScreen.PAYMENT);

      if (appStore.locationSettings?.reviewsEnabled) {
        this.flow.push(TippyScreen.REVIEW);
      }

      this.flow.push(TippyScreen.SUCCESS);

      appStore.goToNextScreen();
    });
  }

  private removeRemainingScreens() {
    appStore.flow.length = appStore.currentStep() + 1;
  }

  private async addPoolsFlow(pool: PoolInfo) {
    if (this.codePrefix !== CodePrefix.STATION) {
      await appStore.setLocationSettings(pool.locationUid);
    }

    if (pool.requiresCustomerName || pool.requiresRoomNumber) {
      this.flow.push(TippyScreen.GUEST_VERIFICATION);
    }

    if (pool.tippingMode === TippingMode.PER_NIGHT) {
      this.flow.push(TippyScreen.NIGHTS);
    }

    this.flow.push(TippyScreen.PROPOSED_TIP_HOSPITALITY);
    this.flow.push(TippyScreen.PAYMENT);

    if (pool.requiresNote || pool.requiresReview) {
      this.flow.push(TippyScreen.REVIEW);
    }

    this.flow.push(TippyScreen.SUCCESS);
  }

  private addUsersFlow(users: User[]) {
    if (users.length > 1) {
      this.flow.push(TippyScreen.USERS);
    } else if (users.length === 1) {
      appStore.selectUser(users[0]).then();
    } else {
      this.flow.push(TippyScreen.USERS);
    }

    this.flow.push(TippyScreen.PROPOSED_TIP_BEAUTY);
    this.flow.push(TippyScreen.PAYMENT);

    if (appStore.locationSettings?.reviewsEnabled) {
      this.flow.push(TippyScreen.REVIEW);
    }

    this.flow.push(TippyScreen.SUCCESS);
  }

  async postReview(review: number, note: string) {
    let tipUids: string[];
    if (appStore.poolPaymentResponse) {
      tipUids = appStore.poolPaymentResponse.tips.map((tip) => tip.uid);
    } else if (appStore.userPaymentResponse) {
      tipUids = appStore.userPaymentResponse.tips.map((tip) => tip.uid);
    }

    await TipsAPI.postReview(tipUids!, review, note);
    appStore.goToNextScreen();
  }

  showAlert(title: string, text: string, severity: Severity) {
    appStore.alertMessage = {
      open: true,
      title,
      text,
      severity,
    };
  }

  getCardholderName(tokenized): string {
    let cardholder = 'Cardholder';

    const billing = tokenized?.details?.billing;
    if (billing?.givenName) {
      cardholder = billing.givenName;

      if (billing?.familyName) {
        cardholder = cardholder + ' ' + billing.familyName;
      }
    }

    return cardholder;
  }

  async tokenizePayment(paymentMethod: PaymentMethod): Promise<TokenResult | undefined> {
    let tokenized: TokenResult = {} as TokenResult;
    try {
      tokenized = await SquarePayments.tokenize(paymentMethod);
      if (tokenized.status === 'Cancel') {
        appStore.ongoingPayment = false;
        return undefined;
      }

      return tokenized;
    } catch (e) {
      appStore.ongoingPayment = false;
      appStore.showAlert('Payment Failed - no token', '', 'error');
      return undefined;
    }
  }

  async initiatePayment(paymentMethod: PaymentMethod) {
    if (appStore.tipIntentParams) {
      await appStore.initiateIntentPayment(paymentMethod);
    } else if (appStore.selectedPools) {
      await appStore.initiatePoolPayment(paymentMethod);
    } else {
      await appStore.initiateUserPayment(paymentMethod);
    }
  }

  private async initiatePoolPayment(paymentMethod: PaymentMethod) {
    appStore.ongoingPayment = true;

    const tokenized = (await appStore.tokenizePayment(paymentMethod)) as TokenResult;
    if (!tokenized) {
      return;
    }

    const tips: PoolTipWebRequest[] = tipStore.selectedTips!.map((tip, idx) => {
      let customerName = 'Cardholder';
      if (
        appStore.selectedLastNames !== undefined &&
        appStore.selectedLastNames[idx] !== undefined &&
        appStore.selectedLastNames[idx] !== ''
      ) {
        customerName = appStore.selectedLastNames[idx];
      }

      const req = {
        poolUid: appStore.selectedPools![idx].uid,
        amount: tipStore.feeResponses![idx].total,
        level: tipStore.selectedTipLevels![idx],
        customerName,
      } as PoolTipWebRequest;

      if (
        appStore.selectedRoomNumbers !== undefined &&
        appStore.selectedRoomNumbers[idx] !== undefined &&
        appStore.selectedRoomNumbers[idx] !== ''
      ) {
        req.roomNumber = appStore.selectedRoomNumbers[0];
      }

      return req;
    });

    try {
      appStore.poolPaymentResponse = await TipsAPI.createPoolWebPayment(
        tokenized.token,
        tips,
        appStore.getCardholderName(tokenized),
      );

      if (appStore.poolPaymentResponse!.status === PaymentStatus.CAPTURED) {
        appStore.ongoingPayment = false;
        appStore.goToNextScreen();
      } else {
        throw new Error('Payment failed');
      }
    } catch (e) {
      appStore.ongoingPayment = false;
      appStore.showAlert(
        'Payment Failed',
        'We were unable to process the payment using this card, please verify that entered card information are correct or try another card',
        'error',
      );
    }
  }

  private async initiateUserPayment(paymentMethod: PaymentMethod) {
    appStore.ongoingPayment = true;

    const tokenized = (await appStore.tokenizePayment(paymentMethod)) as TokenResult;
    if (!tokenized) {
      return;
    }

    const tips: TipRequest[] = tipStore.selectedTips!.map((tip, idx) => ({
      amount: tipStore.feeResponses![idx].total,
      level: tipStore.selectedTipLevels![idx],
      userUid: appStore.selectedUsers![idx].uid,
      locationUid: appStore.selectedLocation!.uid,
    }));

    try {
      appStore.userPaymentResponse = await TipsAPI.createPayment(
        tokenized.token,
        tips,
        appStore.getCardholderName(tokenized),
      );

      if (appStore.userPaymentResponse!.status === PaymentStatus.CAPTURED) {
        appStore.ongoingPayment = false;
        appStore.goToNextScreen();
      } else {
        throw new Error('Payment failed');
      }
    } catch (e) {
      appStore.ongoingPayment = false;
      appStore.showAlert(
        'Payment Failed',
        'We were unable to process the payment using this card, please verify that entered card information are correct or try another card',
        'error',
      );
    }
  }

  private async initiateIntentPayment(paymentMethod: PaymentMethod) {
    appStore.ongoingPayment = true;

    const tokenized = (await appStore.tokenizePayment(paymentMethod)) as TokenResult;
    if (!tokenized) {
      return;
    }

    const tips: TipRequest[] = tipStore.selectedTips!.map((tip, idx) => ({
      amount: tipStore.feeResponses![idx].total,
      level: tipStore.selectedTipLevels![idx],
      userUid: appStore.selectedUsers![idx].uid,
    }));

    try {
      const deviceId = parseInt(appStore.tipIntentParams!.id);
      const { intent } = appStore.tipIntentParams!;

      if (appStore.tipIntentParams?.intentType === IntentType.QR) {
        appStore.userPaymentResponse = await TipsAPI.createQRPayment(
          tokenized.token,
          appStore.tipIntentParams!,
          tips,
          appStore.getCardholderName(tokenized),
        );
      } else {
        appStore.userPaymentResponse = await TipsAPI.createPayLaterQRPayment(
          tokenized.token,
          appStore.tipIntentParams!,
          tips,
          appStore.getCardholderName(tokenized),
        );
      }

      if (appStore.userPaymentResponse!.status === PaymentStatus.CAPTURED) {
        appStore.ongoingPayment = false;
        appStore.goToNextScreen();
      } else {
        throw new Error('Payment failed');
      }
    } catch (e) {
      appStore.ongoingPayment = false;
      appStore.showAlert(
        'Payment Failed',
        'We were unable to process the payment using this card, please verify that entered card information are correct or try another card',
        'error',
      );
    }
  }

  closeSnackbar() {
    appStore.alertMessage.open = false;
    appStore.alertMessage.title = '';
    appStore.alertMessage.text = '';
  }

  getPaymentInfo() {
    return {
      countryCode: 'US',
      currencyCode: 'USD',
      lineItems: [{ amount: tipStore.totalSum().toString(), label: 'Tip', pending: false }],
      total: {
        amount: tipStore.totalSum().toString(),
        label: 'Total',
        pending: false,
      },
    } as PaymentRequestOptions;
  }

  getItems(type: Item['type']): Item[] | undefined {
    const { filteredItems } = appStore;
    const items = filteredItems;
    if (!items || !items.length) return undefined;
    return items.filter((item: Item) => item.type === type);
  }

  isExistingItem(item: string) {
    switch (item) {
      case TippyItems.STATION:
        return true;
      case TippyItems.LOCATION:
        return true;
      case TippyItems.GROUP:
        return true;
      default:
        return false;
    }
  }

  searchItems(searchValue: string) {
    if (!this.items || !this.items.length) this.filteredItems = undefined;

    const strContainsSearchValue = (str) => str?.toLowerCase()?.includes(searchValue.toLowerCase());

    this.filteredItems = this.items?.filter((item: Item) => {
      if (item.type === ItemType.USER) {
        const { user } = item;
        if (strContainsSearchValue(user.firstName) || strContainsSearchValue(user.lastName)) {
          return true;
        }
      } else if (item.type === ItemType.POOL) {
        const { pool } = item;
        if (strContainsSearchValue(pool.name)) {
          return true;
        }
      } else if (item.type === ItemType.GROUP) {
        const { group } = item;
        if (strContainsSearchValue(group.name)) {
          return true;
        }
      }
      return false;
    });
  }

  clearFilteredItems() {
    this.filteredItems = this.items;
  }
}

export const appStore = new AppStore();
