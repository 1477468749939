import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import MainLayout from 'layout/hospitality/MainLayout';
import SpacerV from 'components/common/SpacerV';
import Description from 'components/common/Description';
import TCounterField from 'components/common/TCounterField';
import TButtonPrimary from 'components/common/TButtonPrimary';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { isNormalNumber } from '../../utils';
import { appStore } from '../../stores/AppStore';

const Nights = observer(() => {
  let poolIdx = 0;
  if (appStore.selectedPools !== undefined && appStore.selectedPools.length > 0) {
    poolIdx = appStore.selectedPools.length - 1;
  }

  useEffect(() => {
    if (appStore.selectedNumberOfNights[poolIdx] === undefined) {
      appStore.selectedNumberOfNights.push(0);
      appStore.selectedNumberOfNights = [...appStore.selectedNumberOfNights];
    }
  }, [appStore.selectedNumberOfNights]);

  const button = (
    <TButtonPrimary
      disabled={appStore.selectedNumberOfNights[poolIdx] === 0 || appStore.selectedNumberOfNights[poolIdx] === undefined}
      fullWidth
      onPress={() => {
        appStore.goToNextScreen();
      }}
    >
      Next
    </TButtonPrimary>
  );

  return (
    <MainLayout button={button}>
      <Box justifyContent="center" alignItems="center">
        <Description value="How many nights did you stay at the hotel?" />
        <SpacerV />
        <TCounterField
          counter={appStore.selectedNumberOfNights[poolIdx]}
          value={appStore.selectedNumberOfNights[poolIdx]?.toString() || 0}
          onChange={(e) => {
            const { value } = e.target;
            if (isNormalNumber(value)) {
              appStore.selectedNumberOfNights[poolIdx] = parseInt(value);
              appStore.selectedNumberOfNights = [...appStore.selectedNumberOfNights];
            } else if (isEmpty(value)) {
              appStore.selectedNumberOfNights[poolIdx] = 0;
              appStore.selectedNumberOfNights = [...appStore.selectedNumberOfNights];
            }
          }}
          onIncrement={() => {
            appStore.selectedNumberOfNights[poolIdx] += 1;
            appStore.selectedNumberOfNights = [...appStore.selectedNumberOfNights];
          }}
          onDecrement={() => {
            if (appStore.selectedNumberOfNights[poolIdx] > 0) {
              appStore.selectedNumberOfNights[poolIdx] -= 1;
              appStore.selectedNumberOfNights = [...appStore.selectedNumberOfNights];
            }
          }}
        />
      </Box>
    </MainLayout>
  );
});

export default Nights;
