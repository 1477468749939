import { makeStyles } from '@material-ui/core';
import { appStore } from 'stores';
import { createMainTheme } from 'theme';

const useStyles = (props) => {
  const theme = createMainTheme(appStore.theme);

  return makeStyles(() => ({
    userCard: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'start',
      alignContent: 'center',
      flex: 1,
      height: '100%',
      maxHeight: props.width * 0.6,
      padding: 0,
      color: theme.palette.text.primary,
    },
    userCardContent: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignContent: 'center',
      alignItems: 'center',
      padding: 0,
    },
    userImgContainer: {
      overflow: 'hidden',
    },
    userImg: {
      width: props.width * 0.48,
      marginBottom: '1em',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    nameText: {
      fontWeight: 600,
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'clip',
      paddingTop: '1rem',
    },
    icon: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  }));
};

export default useStyles;
