import { Checkbox } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createMainTheme } from '../../theme';
import { appStore } from '../../stores';

const useStyles = makeStyles(() => {
  const theme = createMainTheme(appStore.theme);

  return {
    checkbox: {
      color: '$000',
      '&.Mui-checked': {
        color: theme.palette.primary.main,
      },
    },
  };
});

export const TCheckbox = ({ ...props }) => {
  const classes = useStyles();

  return <Checkbox className={classes.checkbox} {...props} />;
};
