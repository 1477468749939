import React from 'react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core';
import { TText } from './TText';

const styles = (theme: Theme) =>
  createStyles({
    text: {
      color: theme.palette.text.secondary,
      textAlign: 'center',
    },
  });

interface Props extends WithStyles<typeof styles> {
  value: string;
  subtitle?: string;
}
const Title: React.StatelessComponent<Props> = ({ value, subtitle, classes }: Props) => (
  <>
    <TText variant="h4" style={{ color: 'black', textAlign: 'center', fontWeight: '500' }}>
      {value}
    </TText>
    <TText className={classes.text}>{subtitle}</TText>
  </>
);

Title.defaultProps = { subtitle: '' };

export default withStyles(styles)(Title);
