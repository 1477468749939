import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import MainLayout from 'layout/hospitality/MainLayout';
import SpacerV from 'components/common/SpacerV';
import Description from 'components/common/Description';
import TTextField from 'components/common/TTextField';
import TButtonPrimary from 'components/common/TButtonPrimary';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { isNormalNumber } from '../../utils';
import { appStore } from '../../stores/AppStore';
import PoolInfo from '../../models/PoolInfo';

const GuestVerification = observer(() => {
  let selectedPool: PoolInfo | undefined;
  if (appStore.selectedPools) {
    // eslint-disable-next-line prefer-destructuring
    selectedPool = appStore.selectedPools![0];
  }

  let poolIdx = 0;
  if (appStore.selectedPools !== undefined && appStore.selectedPools.length > 0) {
    poolIdx = appStore.selectedPools.length - 1;
  }

  const [inputValid, setInputValid] = useState<boolean>(false);

  useEffect(() => {
    if (appStore.selectedRoomNumbers[poolIdx] === undefined) {
      appStore.selectedRoomNumbers.push('');
      appStore.selectedRoomNumbers = [...appStore.selectedRoomNumbers];
    }

    if (appStore.selectedLastNames[poolIdx] === undefined) {
      appStore.selectedLastNames.push('');
      appStore.selectedLastNames = [...appStore.selectedLastNames];
    }

    let isInputValid = true;
    if (selectedPool?.requiresCustomerName) {
      if (appStore.selectedLastNames[poolIdx] === '') {
        isInputValid = false;
      }
    }

    if (selectedPool?.requiresRoomNumber) {
      if (appStore.selectedRoomNumbers[poolIdx] === '') {
        isInputValid = false;
      }
    }

    setInputValid(isInputValid);
  }, [appStore.selectedRoomNumbers, appStore.selectedLastNames]);

  const button = (
    <TButtonPrimary
      disabled={!inputValid}
      fullWidth
      onPress={() => {
        appStore.goToNextScreen();
      }}
    >
      Next
    </TButtonPrimary>
  );

  return (
    <MainLayout button={button}>
      <Box justifyContent="center" alignItems="center">
        <Description value={appStore.customerInstructions} />
        <SpacerV />
        {selectedPool?.requiresRoomNumber && appStore.selectedRoomNumbers[poolIdx] !== undefined && (
          <TTextField
            label="Room Number"
            type="tel"
            fullWidth
            value={appStore.selectedRoomNumbers[poolIdx]}
            onChange={(e) => {
              const { value } = e.target;

              if (isNormalNumber(value)) {
                appStore.selectedRoomNumbers[poolIdx] = value;
                appStore.selectedRoomNumbers = [...appStore.selectedRoomNumbers];
              } else if (isEmpty(value)) {
                appStore.selectedRoomNumbers[poolIdx] = '';
                appStore.selectedRoomNumbers = [...appStore.selectedRoomNumbers];
              }
            }}
          />
        )}
        {selectedPool?.requiresCustomerName && appStore.selectedLastNames[poolIdx] !== undefined && (
          <TTextField
            label="Last Name"
            fullWidth
            value={appStore.selectedLastNames[poolIdx]}
            onChange={(e) => {
              appStore.selectedLastNames[poolIdx] = e.target.value;
              appStore.selectedLastNames = [...appStore.selectedLastNames];
            }}
          />
        )}
      </Box>
    </MainLayout>
  );
});

export default GuestVerification;
