import React, { useState } from 'react';
import { alpha, Box, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import TTextField from 'components/common/TTextField';
import { Clear } from '@material-ui/icons';
import SpacerV from 'components/common/SpacerV';
import { appStore } from '../../stores/AppStore';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100vh',
    padding: theme.spacing(2),
  },
  header: {
    flex: '0 1 auto',
  },
  content: {
    flex: '1 1 auto',
    backgroundColor: theme.palette.background.default,
  },
  footer: {
    flex: '0 1 40px',
    textAlign: 'center',
    paddingBottom: 60,
  },
  appBar: {
    backgroundColor: 'transparent',
    height: '5.5vh',
  },
  navigation: {
    textAlign: 'center',
  },
  locationLogo: {
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '8vh',
    height: '8vh',
    position: 'absolute',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    position: 'relative',
  },
  icon: {
    color: alpha(theme.palette.text.secondary, 0.5),
    width: '4vh',
    height: '4vh',
  },
  iconBox: {
    display: 'flex',
    padding: '0.5vh',
    marginLeft: 'auto',
  },
  clearIcon: {
    color: theme.palette.grey[500],
  },
  '@keyframes searchEfect': {
    '0%': {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 0,
    },
    '100%': {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    },
  },
  search: {
    animation: `$searchEfect 500ms ${theme.transitions.easing.easeInOut}`,
  },

  iconSmall: {
    color: theme.palette.primary.main,
  },
}));

const ItemsSearch = ({ closeSearch, showItemsSearch, openSearchItems, setOpenSearch, small }: any) => {
  const classes = useStyles();
  const [itemSearchValue, setSearchValue] = useState('');

  const handleClear = () => {
    if (itemSearchValue.length) {
      setSearchValue('');
    } else {
      closeSearch();
    }
    appStore.clearFilteredItems();
  };

  const searchItems = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(e.target.value);
    appStore.searchItems(e.target.value);
  };

  if (small) {
    return (
      <div>
        <TTextField
          style={{ width: '100%' }}
          value={itemSearchValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.iconSmall} />
              </InputAdornment>
            ),
            endAdornment: itemSearchValue && (
              <InputAdornment position="start">
                <Clear className={classes.iconSmall} onClick={() => handleClear()} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          onChange={searchItems}
          placeholder="Type to search"
        />
        <SpacerV size="small" />
      </div>
    );
  }

  return (
    <div>
      <TTextField
        className={classes.search}
        style={{ width: '100%' }}
        value={itemSearchValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Clear className={classes.clearIcon} onClick={handleClear} />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        onChange={searchItems}
        placeholder="Type to search"
      />

      {showItemsSearch && !openSearchItems && (
        <Box className={classes.iconBox} onClick={() => setOpenSearch(true)}>
          <SearchIcon className={classes.icon} />
        </Box>
      )}
      {!openSearchItems && !showItemsSearch && <Box width={10} />}
    </div>
  );
};

export default ItemsSearch;
