import React, { useEffect, useState } from 'react';
import useDimensions from 'react-use-dimensions';
import { User } from 'models';
import { Item, ItemType, IUser } from 'models/Items';
import { appStore } from 'stores/AppStore';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { toJS } from 'mobx';
import useStyles from './styles';
import app from '../../../App';

type UseUsers = [
  any,
  ClassNameMap,
  User[] | undefined,
  (user: User) => void,
  () => '' | 'Or a person' | 'Choose a person you want to tip',
];

export const useUsers = (): UseUsers => {
  const [ref, { width, height }] = useDimensions();
  const classes = useStyles({ height, width })();
  const [users, setUsers] = useState<User[] | undefined>();

  useEffect(() => {
    const userItems = appStore.getItems(ItemType.USER) as IUser[];
    if (userItems) {
      const _users = userItems.map((user: IUser): User => user.user);
      setUsers(_users);
    }
  }, [appStore.filteredItems]);

  function usersTitle() {
    if (!appStore.filteredItems || !appStore.filteredItems.length) return '';

    const group = appStore.filteredItems.find(
      (item: Item) => item.type === ItemType.POOL || item.type === ItemType.GROUP,
    );
    return group ? 'Or a person' : 'Choose a person you want to tip';
  }

  const onClickUser = (user: User) => {
    if (appStore.selectedUsers !== undefined && appStore.selectedUsers.length > 0) {
      appStore.addSelectedUser(user).then();
    } else {
      appStore.selectUserAndAddFlow(user).then();
    }
  };

  return [ref, classes, users, onClickUser, usersTitle];
};
