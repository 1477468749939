import { Checkbox, CircularProgress } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createMainTheme } from '../../theme';
import { appStore } from '../../stores';

const useStyles = makeStyles(() => {
  const theme = createMainTheme(appStore.theme);

  return {
    checkbox: {
      color: theme.palette.primary.main,
    },
  };
});

export const TCircularProgress = ({ ...props }) => {
  const classes = useStyles();

  return <CircularProgress className={classes.checkbox} {...props} />;
};
