import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import MainLayout from 'layout/hospitality/MainLayout';
import TButtonPrimary from 'components/common/TButtonPrimary';
import SpacerV from 'components/common/SpacerV';
import { ApplePay, Card, GooglePay } from 'components/square';
import type { Card as SQCard, Payments } from '@square/web-payments-sdk-types';
import SquarePayments from 'components/square/Payments';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Popover,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useHistory } from 'react-router-dom';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { toJS } from 'mobx';
import TCard from '../../components/common/TCard';
import AlertPopup from '../../components/AlertPopup';
import { appStore } from '../../stores/AppStore';
import { tipStore } from '../../stores/TipStore';
import { TText } from '../../components/common/TText';
import { createMainTheme } from '../../theme';
import { TCheckbox } from '../../components/common/TCheckbox';
import { TCircularProgress } from '../../components/common/TCircularProgress';
import { TippyScreen } from '../../models/TippyScreen';
import { CodePrefix } from '../../models/CodePrefix';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    height: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    margin: '0px 10px',
  },
  iconFilled: {
    color: theme.palette.primary.main,
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  avatarRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoPopup: {
    alignContent: 'center',
    alignItems: 'center',
  },
  infoPopupTitle: {
    fontSize: 18,
    marginBottom: 8,
  },
  infoPopupText: {
    fontSize: 14,
  },
  avatar: {
    width: '2em',
    height: '2em',
    marginRight: 10,
  },
}));

const dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const PaymentScreen = observer(() => {
  const classes = useStyles();
  const theme = createMainTheme(appStore.theme);
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [squarePayments, setSquarePayments] = useState({} as Payments);
  const [squareCard, setSquareCard] = useState({} as SQCard);

  const [allCreditCardFieldsValid, setAllCreditCardFieldsValid] = useState(false);

  const [popupAnchorEl, setPopupAnchorEl] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [dialogCallback, setDialogCallback] = useState(() => () => {});

  const handleInfoClick = (event) => {
    setPopupAnchorEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setPopupAnchorEl(null);
  };

  const open = Boolean(popupAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  const button = () => (
    <TButtonPrimary
      fullWidth
      disabled={!allCreditCardFieldsValid || appStore.ongoingPayment}
      onPress={() => {
        appStore.initiatePayment(squareCard).then();
      }}
    >
      Pay {dollarFormatter.format(tipStore.totalSum())}
    </TButtonPrimary>
  );

  useEffect(() => {
    SquarePayments.setLoader(setLoaded);
  }, [setLoaded]);

  useEffect(() => {
    if (loaded && isEmpty(squarePayments)) {
      SquarePayments.load(setSquarePayments);
    }
  }, [loaded, squarePayments, setSquarePayments]);

  const setCard = (card: SQCard) => {
    setSquareCard(card);
  };

  const loadedAll =
    tipStore.feeResponses && loaded && !appStore.loadingData && tipStore.feeResponses[0]?.tipAmount !== undefined;

  const optionalCheckText = "The convenience fee is on me! Let's give the full tip to the staff.";

  return (
    <div>
      {!loadedAll && (
        <Box alignItems="center" justifyContent="center" className={classes.root}>
          <TCircularProgress />
        </Box>
      )}
      {loadedAll && (
        <MainLayout button={button()} showToS>
          <TCard>
            <div className={classes.cardRow}>
              <TText style={{ fontSize: 18 }}>Total</TText>
              <TText style={{ fontWeight: 'bold', fontSize: 18 }}>{dollarFormatter.format(tipStore.totalSum())}</TText>
            </div>
            <Divider />
            {appStore.selectedPools?.map((pool, idx) => (
              <div key={`${pool.uid}_${idx}`}>
                <div className={classes.cardRow}>
                  <div className={classes.avatarRow}>
                    <TText style={{ fontSize: 18 }}>{pool?.name}</TText>
                    {appStore.selectedPools!.length > 1 && (
                      <IconButton
                        onClick={() => {
                          setDialogText(`Are you sure you want to remove ${pool?.name} from the tip?`);
                          setDialogCallback(() => () => appStore.removeSelectedPool(idx));
                          setDialogOpen(true);
                        }}
                      >
                        <CancelOutlinedIcon style={{ color: theme.palette.text.primary }} />
                      </IconButton>
                    )}
                  </div>
                  <TText style={{ fontSize: 18 }}>
                    {dollarFormatter.format(parseFloat(tipStore.feeResponses![idx]?.tipAmount))}
                  </TText>
                </div>
                <Divider />
              </div>
            ))}
            {appStore.selectedUsers?.map((user, idx) => (
              <div key={`${user.uid}_${idx}`}>
                <div className={classes.cardRow}>
                  {user && (
                    <div className={classes.avatarRow}>
                      <Avatar alt={user.firstName} src={user.avatar} className={classes.avatar} />
                      <TText style={{ fontSize: 18 }}>
                        {user?.nickname ? user?.nickname : `${user?.firstName} ${user?.lastName}`}
                      </TText>
                      {appStore.selectedUsers!.length > 1 && (
                        <IconButton
                          onClick={() => {
                            setDialogText(`Are you sure you want to remove ${user.firstName} from the tip?`);
                            setDialogCallback(() => () => appStore.removeSelectedUser(idx));
                            setDialogOpen(true);
                          }}
                        >
                          <CancelOutlinedIcon style={{ color: theme.palette.text.primary }} />
                        </IconButton>
                      )}
                    </div>
                  )}
                  <TText style={{ fontSize: 18 }}>
                    {dollarFormatter.format(parseFloat(tipStore.feeResponses![idx]?.tipAmount))}
                  </TText>
                </div>
                <Divider />
              </div>
            ))}
            <div className={classes.cardRow}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TText style={{ color: theme.palette.text.secondary }}>Convenience fee</TText>
                <IconButton onClick={handleInfoClick}>
                  <InfoIcon style={{ color: theme.palette.text.secondary, fontSize: 18 }} />
                </IconButton>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={popupAnchorEl}
                  onClose={handleInfoClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  className={classes.infoPopup}
                >
                  <Box p={2}>
                    <TText className={classes.infoPopupTitle}>What is this?</TText>
                    <TText className={classes.infoPopupText}>
                      Ensures your service professional receives your generous tip directly
                    </TText>
                  </Box>
                </Popover>
              </div>

              <TText style={{ color: theme.palette.text.secondary, fontSize: 18 }}>
                {dollarFormatter.format(tipStore.feesSum())}
              </TText>
            </div>
          </TCard>

          {appStore.locationSettings?.transparency === 'optional' && (
            <div>
              <SpacerV size="small" />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {tipStore.refreshingFee && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: '0.64em',
                      marginRight: '0.64em',
                    }}
                  >
                    <TCircularProgress size={24} />
                  </div>
                )}
                {!tipStore.refreshingFee && (
                  <TCheckbox
                    checked={tipStore.extraOptionalTipChecked}
                    onChange={(e) => {
                      tipStore.extraOptionalTipChecked = e.target.checked;
                      tipStore.recalculateFees().then();
                    }}
                  />
                )}
                <TText>{optionalCheckText}</TText>
              </div>
            </div>
          )}

          {appStore.codePrefix !== CodePrefix.USER && appStore.preSelectedUserUid === undefined && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }}>
              <Button
                color="primary"
                onClick={() => {
                  document.getElementById('webPayment')?.remove();
                  appStore.currentScreen = TippyScreen.LOCATION;
                }}
              >
                Add another
              </Button>
            </div>
          )}

          <SpacerV size="large" />
          <ApplePay
            payments={squarePayments}
            initiatePayment={appStore.initiatePayment}
            getPaymentInfo={appStore.getPaymentInfo}
          />

          <SpacerV size="small" />

          <GooglePay
            payments={squarePayments}
            initiatePayment={appStore.initiatePayment}
            getPaymentInfo={appStore.getPaymentInfo}
          />

          <SpacerV size="small" />

          <Card payments={squarePayments} onLoad={setCard} setAllValid={setAllCreditCardFieldsValid} />

          <SpacerV size="small" />

          <AlertPopup
            open={appStore.alertMessage.open}
            onClose={appStore.closeSnackbar}
            title={appStore.alertMessage.title}
            text={appStore.alertMessage.text}
            severity={appStore.alertMessage.severity}
          />

          <Dialog
            open={dialogOpen}
            onClose={() => {
              setDialogOpen(false);
            }}
          >
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
              <DialogContentText>{dialogText}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setDialogOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  dialogCallback();
                  setDialogOpen(false);
                }}
                color="primary"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </MainLayout>
      )}
    </div>
  );
});

export default PaymentScreen;
