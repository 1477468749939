import React from 'react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
    },
  });

interface Props extends WithStyles<typeof styles> {
  onAction?: () => void;
}

const Action: React.StatelessComponent<Props> = (props) => {
  const { classes, children } = props;

  return (
    <div onClick={props.onAction} className={classes.root}>
      {children}
    </div>
  );
};

export default withStyles(styles)(Action);
