import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { CodePrefix } from '../../models/CodePrefix';
import config from '../../config';
import { appStore } from '../../stores/AppStore';
import { tipStore } from '../../stores/TipStore';
import { TCircularProgress } from '../../components/common/TCircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    height: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const getCodePrefix = (code: string) => {
  const codePrefix = code.charAt(0) as CodePrefix;

  if (codePrefix === undefined) {
    throw new Error('Could not parse QR code prefix');
  }

  return codePrefix;
};

const payloadEndpointByPrefix = {
  L: `${config.api.core}/v3/web/locations`,
  P: `${config.api.core}/v2/web/pools`,
  S: `${config.api.core}/v2/web/stations`,
  A: `${config.api.core}/v2/web/accounts`,
  U: `${config.api.core}/v1/web/users`,
};

const Landing = () => {
  const classes = useStyles();

  const { code } = useParams<{ code: string }>();
  const { preSelectedUserUid } = useParams<{ preSelectedUserUid: string }>();

  const strippedCode = code.substring(1);
  const [requested, setRequested] = useState(false);

  useEffect(() => {
    if (!appStore.initialized) {
      return;
    }

    if (!requested) {
      appStore.resetValues(preSelectedUserUid);
      tipStore.resetValues();

      const codePrefix = getCodePrefix(code);
      const endpoint = payloadEndpointByPrefix[codePrefix];
      if (!endpoint) {
        appStore.goToNotFoundScreen();
      }

      appStore.code = code;
      const tipUrl = `${endpoint}/${strippedCode}`;
      setRequested(true);
      appStore.exchangeCode(codePrefix, tipUrl).then();
    }
  }, [code, appStore.initialized, requested, strippedCode]);

  return (
    <Box alignItems="center" justifyContent="center" className={classes.root}>
      <TCircularProgress />
    </Box>
  );
};

export default Landing;
