import React from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
// import { StationItemType, StationItem, IStationUser, IStationPool, IStationGroup, Group } from 'models/Station';
import NotFound from 'containers/NotFound';
import ItemsLayout from 'layout/items/ItemsLayout';
import { appStore } from '../../stores';
import { Users } from './Users/Users';
import { PoolsAndGroups } from './Groups/Groups';

const Items = observer(() => {
  const { item, uid } = useParams<{ item: string; uid?: string }>();
  if (appStore.isExistingItem(item)) {
    return (
      <ItemsLayout>
        <PoolsAndGroups />
        <Users />
      </ItemsLayout>
    );
  }

  return <NotFound />;
});

export default Items;
