import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import Landing from 'containers/landing/Landing';
import Department from 'containers/department/Department';
import GuestVerification from 'containers/guestVerification/GuestVerification';
import Nights from 'containers/nights/Nights';
import ProposedTipHospitality from 'containers/proposedTip/ProposedTipHospitality';
import Payment from 'containers/payment/PaymentScreen';
import Review from 'containers/review/Review';
import NotFound from 'containers/NotFound';
import { observer } from 'mobx-react';
import IntentLanding from 'containers/landing/IntentLanding';
import ProposedTipIntent from 'containers/proposedTip/ProposedTipIntent';
import Items from 'containers/Items/Items';
import Success from '../containers/success/Success';
import Locations from '../containers/locations/Locations';
import ProposedTipBeauty from '../containers/proposedTip/ProposedTipBeauty';
import { appStore, IntentType } from '../stores/AppStore';
import { TippyScreen } from '../models/TippyScreen';

const Routes = observer(() => {
  const history = useHistory();
  const { pathname } = history.location;

  const pathWords = pathname.substring(1).split('/');

  useEffect(() => {
    if (
      pathWords[1] !== undefined &&
      pathWords[0] !== 'qr' &&
      pathWords[0] !== 'pl' &&
      !appStore.payload &&
      appStore.initialized
    ) {
      let preSelectedUserUid;

      if (pathWords[1] === TippyScreen.PROPOSED_TIP_BEAUTY) {
        if (pathWords.length >= 3 && pathWords[2]) {
          // eslint-disable-next-line prefer-destructuring
          preSelectedUserUid = pathWords[2];
          appStore.preSelectedUserUid = preSelectedUserUid;
        }
      }

      let landingPath = `/${pathWords[0]}`;
      if (preSelectedUserUid) {
        landingPath += `/user/${preSelectedUserUid}`;
      }
      history.push(landingPath);
    }
  }, [appStore.payload, appStore.initialized, history]);

  useEffect(() => {
    let route = '';

    if (appStore.currentScreen !== undefined) {
      if (appStore.tipIntentParams !== undefined) {
        const prefix = appStore.tipIntentParams.intentType === IntentType.QR ? '/qr' : '/pl';

        route = `${prefix}/${appStore.tipIntentParams!.id}/${encodeURIComponent(appStore.tipIntentParams!.intent)}/${
          appStore.currentScreen
        }`;
      } else {
        route = `/${appStore.code}/${appStore.currentScreen}`;
      }

      if (appStore.currentSubrouteIndex) {
        route += `/${appStore.currentSubrouteIndex}`;
      }

      if (
        appStore.currentScreen === TippyScreen.PROPOSED_TIP_BEAUTY &&
        appStore.selectedUsers &&
        appStore.selectedUsers.length > 0
      ) {
        route += `/${appStore.selectedUsers![0].uid}`;
      }

      if (appStore.currentScreen === TippyScreen.GROUP && appStore.group) {
        route += `/${appStore.group.uid}`;
      }

      history.push(route);
    }
  }, [
    appStore.currentScreen,
    appStore.currentSubrouteIndex,
    appStore.code,
    appStore.tipIntentParams,
    history,
    appStore.group,
  ]);

  useEffect(() => {
    if (!appStore.historySub) {
      appStore.historySub = history.listen((newLocation, action) => {
        if (action === 'PUSH') {
          if (newLocation.pathname !== appStore.currentPathname) {
            // Save new location
            appStore.currentPathname = newLocation.pathname;

            // Clone location object and push it to history
            history.push({
              pathname: newLocation.pathname,
            });
          }
        } else {
          // Send user back if they try to navigate back
          history.go(1);
        }
      });
    }
  }, [history]);

  if (!appStore.initialized) {
    return (
      <Switch>
        <Route exact path="/qr/:id/:intent" component={IntentLanding} />
        <Route exact path="/pl/:id/:intent" component={IntentLanding} />
        <Route exact path="/:code/not-found" component={NotFound} />
        <Route exact path="/:code/landing" component={Landing} />
        <Route exact path="/:code/user/:preSelectedUserUid" component={Landing} />
        <Route exact path="/:code" component={Landing} />
        <Route>
          <div />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/qr/:id/:intent" component={IntentLanding} />
      <Route exact path="/pl/:id/:intent" component={IntentLanding} />
      <Route path="/qr/:id/:intent/proposed-tip-intent" component={ProposedTipIntent} />
      <Route path="/qr/:id/:intent/payment" component={Payment} />
      <Route path="/qr/:id/:intent/success" component={Success} />
      <Route path="/pl/:id/:intent/proposed-tip-intent" component={ProposedTipIntent} />
      <Route path="/pl/:id/:intent/payment" component={Payment} />
      <Route path="/pl/:id/:intent/success" component={Success} />
      <Route exact path="/:code/department" component={Department} />
      <Route exact path="/:code/guest-verification" component={GuestVerification} />
      <Route exact path="/:code/nights" component={Nights} />
      <Route exact path="/:code/proposed-tip-hospitality" component={ProposedTipHospitality} />
      <Route exact path="/:code/proposed-tip-beauty/:userUid" component={ProposedTipBeauty} />
      <Route exact path="/:code/payment" component={Payment} />
      <Route exact path="/:code/review" component={Review} />
      <Route exact path="/:code/talent" component={Review} />
      <Route exact path="/:code/success" component={Success} />
      <Route exact path="/:code/not-found" component={NotFound} />
      <Route exact path="/:code/landing" component={Landing} />
      <Route exact path="/:code/locations" component={Locations} />
      {/* <Route exact path="/:code/users" component={Users} /> */}
      <Route exact path="/:code/user/:preSelectedUserUid" component={Landing} />
      <Route exact path="/:code/:item/:uid?" component={Items} />
      <Route exact path="/:code" component={Landing} />
      <Route component={NotFound} />
    </Switch>
  );
});

export default Routes;
