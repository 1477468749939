import React from 'react';
import { Avatar, Card, CardActionArea, CardContent, Grid } from '@material-ui/core';
import { TText } from 'components/common/TText';
import SpacerV from 'components/common/SpacerV';
import ItemTitle from 'components/common/ItemTitle';
import { observer } from 'mobx-react';
import { useUsers } from './useUsers';

export const Users = observer(() => {
  const [ref, classes, users, onClickUser, usersTitle] = useUsers();

  if (!users || !users.length) {
    return <></>;
  }

  return (
    <>
      <ItemTitle value={usersTitle()} />
      <SpacerV />
      <Grid ref={ref} container spacing={2}>
        {users.map((user, index) => {
          let name = user.nickname;
          if (!name) {
            let surnameInitial = '';
            if (user.lastName) {
              surnameInitial = user.lastName?.charAt(0)?.toUpperCase();
            }
            name = `${user.firstName} ${surnameInitial}`;
          }

          return (
            <Grid item xs={6} key={`${user.uid} ${index}`}>
              <Card className={classes.userCard} onClick={() => onClickUser(user)}>
                <CardActionArea
                  style={{
                    width: '100%',
                    height: '100%',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <CardContent
                    className={classes.userCardContent}
                    style={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }}
                  >
                    <div style={{ flex: 3, overflow: 'hidden', padding: 0, width: '100%' }}>
                      <Avatar
                        src={user.avatar}
                        alt={user.firstName}
                        variant="square"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <TText className={classes.nameText}>{name}</TText>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
});
