import React, { CSSProperties } from 'react';

interface Props {
  size?: 'small' | 'large';
}

const SpacerV: React.StatelessComponent<Props> = (props) => {
  const { size } = props;
  let height;
  switch (size) {
    case 'small':
      height = 10;
      break;
    case 'large':
      height = 50;
      break;
    default:
      height = 30;
  }

  const styles: CSSProperties = {
    height,
  };

  return <div style={styles} />;
};

export default SpacerV;
