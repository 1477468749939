import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { appStore, IntentType } from '../../stores/AppStore';
import { tipStore } from '../../stores/TipStore';
import { TCircularProgress } from '../../components/common/TCircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    height: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const IntentLanding = () => {
  const classes = useStyles();

  const { id, intent } = useParams<{ id: string; intent: string }>();
  const [requested, setRequested] = useState(false);

  const history = useHistory();
  const { pathname } = history.location;

  const pathWords = pathname.substring(1).split('/');

  let intentType: IntentType;

  if (pathWords.length >= 1) {
    if (pathWords[0] === 'qr') {
      intentType = IntentType.QR;
    } else if (pathWords[0] === 'pl') {
      intentType = IntentType.PayLaterSMS;
    }
  }

  useEffect(() => {
    if (!appStore.initialized) {
      return;
    }

    if (!requested) {
      appStore.resetValues();
      tipStore.resetValues();

      setRequested(true);

      if (intentType === IntentType.QR) {
        appStore.exchangeTipIntentCode(id, decodeURIComponent(intent), intentType).then();
      } else if (intentType === IntentType.PayLaterSMS) {
        appStore.exchangePayLaterIntentCode(id, decodeURIComponent(intent), intentType).then();
      } else {
        console.error('intentType not found');
        appStore.goToNotFoundScreen();
      }
    }
  }, [id, intent, appStore.initialized, requested]);

  return (
    <Box alignItems="center" justifyContent="center" className={classes.root}>
      <TCircularProgress />
    </Box>
  );
};

export default IntentLanding;
