import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { appStore } from 'stores';
import Item from 'components/common/Item';
import ItemTitle from 'components/common/ItemTitle';
import SpacerV from 'components/common/SpacerV';
import { IGroup, IPool, ItemType } from 'models/Items';
import { observer } from 'mobx-react';

const Pools = ({ pools }: { pools?: IPool[] }) => {
  if (!pools || !pools.length) return <></>;

  const onClickPool = async (pool: IPool) => {
    await appStore.addSelectedPool(pool.pool);
    appStore.goToNextScreen();
  };

  return (
    <>
      {pools.map((pool, index) => (
        <Item key={pool.name + index} label={pool.name} onClick={() => onClickPool(pool)} />
      ))}
    </>
  );
};

const Groups = ({ groups }: { groups?: IGroup[] }) => {
  if (!groups || !groups.length) return <></>;

  const onClickGroup = async (group: IGroup) => {
    appStore.selectGroup(group.group);
  };

  return (
    <>
      {groups.map((group, index) => (
        <Item key={group.name + index} label={group.name} onClick={() => onClickGroup(group)} />
      ))}
    </>
  );
};

export const PoolsAndGroups = observer(() => {
  const [pools, setPools] = useState<IPool[] | undefined>();
  const [groups, setGroups] = useState<IGroup[] | undefined>();

  useEffect(() => {
    const poolItems = appStore.getItems(ItemType.POOL) as IPool[];
    const groupItems = appStore.getItems(ItemType.GROUP) as IGroup[];
    setPools(poolItems);
    setGroups(groupItems);
  }, [appStore.filteredItems]);

  if ((!pools || !pools.length) && (!groups || !groups?.length)) {
    return <></>;
  }

  return (
    <Grid container alignItems="center" direction="column" spacing={4}>
      <Grid item xs={12} style={{ width: '100%' }}>
        <ItemTitle value="Choose a group you want to tip" />
        <SpacerV />
        <Pools pools={pools} />
        <Groups groups={groups} />
        <SpacerV />
      </Grid>
    </Grid>
  );
});
