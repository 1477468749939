export enum TippyScreen {
  LANDING = 'landing',
  INTENT_LANDING = 'intent_landing',
  LOCATIONS = 'locations',
  LOCATION = 'location',
  STATION = 'station',
  GROUP = 'group',
  USERS = 'users',
  DEPARTMENT = 'department',
  TALENT = 'talent',
  GUEST_VERIFICATION = 'guest-verification',
  NIGHTS = 'nights',
  PROPOSED_TIP_HOSPITALITY = 'proposed-tip-hospitality',
  PROPOSED_TIP_BEAUTY = 'proposed-tip-beauty',
  PROPOSED_TIP_INTENT = 'proposed-tip-intent',
  PAYMENT = 'payment',
  REVIEW = 'review',
  SUCCESS = 'success',
  NOT_FOUND = 'not-found',
}
