import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, IconButton, Input, InputAdornment } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { observer } from 'mobx-react';
import { createMainTheme } from '../../theme';
import { appStore } from '../../stores';

const useStyles = makeStyles((t) => {
  const theme = createMainTheme(appStore.theme);

  return {
    formControl: {
      width: '100%',
    },
    input: {
      border: '1px solid #E0E0E0',
      overflow: 'hidden',
      borderRadius: 16,
      fontWeight: 900,
      fontSize: 21,
      backgroundColor: theme.palette.common.white,
      padding: '10px 20px',
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
      '&$focused': {
        backgroundColor: theme.palette.common.white,
      },
    },
    focused: {
      backgroundColor: theme.palette.common.white,
    },
    expand: {
      color: theme.palette.primary.main,
    },
  };
});

interface CounterFieldProps {
  counter: number;
  onChange: (e) => void;
  onIncrement: () => void;
  onDecrement: () => void;
}

const TCounterField = observer((props) => {
  const classes = useStyles();

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl className={classes.formControl}>
      <Input
        type="tel"
        value={props.counter}
        onChange={props.onChange}
        fullWidth
        disableUnderline
        className={classes.input}
        inputProps={{ style: { textAlign: 'center' } }}
        startAdornment={
          <InputAdornment position="start">
            <IconButton onClick={props.onDecrement} onMouseDown={handleMouseDown}>
              <ExpandMore color="primary" className={classes.expand} />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={props.onIncrement} onMouseDown={handleMouseDown}>
              <ExpandLess color="primary" className={classes.expand} />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
});

export default TCounterField;
