/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { alpha, Avatar, Box, Card, CardActionArea, CardContent, Typography } from '@material-ui/core';
import MainLayout from 'layout/hospitality/MainLayout';
import SpacerV from 'components/common/SpacerV';
import TTextField from 'components/common/TTextField';
import TButtonPrimary from 'components/common/TButtonPrimary';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { isNormalNumber } from '../../utils';
import { TipLevel } from '../../models/TipLevel';
import { appStore } from '../../stores/AppStore';
import { User } from '../../models';
import { TipProposals } from '../../models/TipIntentPayload';
import { tipStore } from '../../stores/TipStore';
import { TText } from '../../components/common/TText';
import { TCircularProgress } from '../../components/common/TCircularProgress';
import { ConfirmationDialog } from '../../components/ConfirmationDialog';

const useStyles = makeStyles((theme) => ({
  userCard: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    margin: 0,
    padding: 0,
  },
  avatar: {
    width: '100%',
    height: '40vh',
  },
  tipOptionsRow: {
    display: 'flex',
    width: '100%',
    columnGap: 14,
  },
  tipCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    '&::before': {
      content: '""',
      display: 'table',
      paddingTop: '100%',
    },
  },

  selectedSquareColor: {
    backgroundColor: alpha(theme.palette.primary.main, 1),
  },
  tipAmountSquare: {
    '&::before': {
      content: '""',
      opacity: 0.1,
    },
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    flex: 1,
  },
  tipAmountText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  tipPercentageText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
}));

interface TipCardProps {
  tipProposals: TipProposals;
  proposalLevel: TipLevel;
  tip: string;
  setTip: (tip: string) => void;
  customTip: string;
  setCustomTip: (tip: string) => void;
  setTipLevel: (tipLevel: TipLevel) => void;
  goToNextTip: (tipAmount) => void;
}

const TipProposalCard = observer(
  ({ tipProposals, proposalLevel, tip, setTip, customTip, setCustomTip, setTipLevel, goToNextTip }: TipCardProps) => {
    const classes = useStyles();

    let tipAmount = '0';

    tipAmount = (() => {
      switch (proposalLevel) {
        case TipLevel.LOW:
          return tipProposals.low.amount;
        case TipLevel.MEDIUM:
          return tipProposals.medium.amount;
        case TipLevel.HIGH:
          return tipProposals.high.amount;
        default:
          throw new Error('');
      }
    })().toString();

    let tipPercentage = (() => {
      switch (proposalLevel) {
        case TipLevel.LOW:
          return tipProposals.low.percent;
        case TipLevel.MEDIUM:
          return tipProposals.medium.percent;
        case TipLevel.HIGH:
          return tipProposals.high.percent;
        default:
          throw new Error('');
      }
    })();

    const tipType = (() => {
      switch (proposalLevel) {
        case TipLevel.LOW:
          return tipProposals.low.type;
        case TipLevel.MEDIUM:
          return tipProposals.medium.type;
        case TipLevel.HIGH:
          return tipProposals.high.type;
        default:
          throw new Error('');
      }
    })().toString();

    tipPercentage = Math.round(parseFloat(tipPercentage)).toString();

    const selected = tip === tipAmount;

    const circleClasses: any[] = [classes.tipAmountSquare];
    if (selected && !customTip) {
      circleClasses.push(classes.selectedSquareColor);
    }

    const onCardClick = () => {
      setTip(tipAmount);
      setTipLevel(proposalLevel);
      setCustomTip('');

      goToNextTip(tipAmount);
    };

    return (
      <Card className={classes.tipCard}>
        <CardActionArea style={{ width: '100%', height: '100%', flex: 1 }} onClick={onCardClick}>
          <CardContent style={{ width: '100%', height: '100%', padding: 0 }}>
            <div className={clsx(circleClasses)}>
              {tipType !== 'amount' && <TText className={classes.tipAmountText}>{tipPercentage}%</TText>}
              <TText className={classes.tipPercentageText}>${tipAmount}</TText>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  },
);

const getName = (user) => {
  let name = user.nickname;
  if (!name) {
    let surnameInitial = '';
    if (user.lastName) {
      surnameInitial = user.lastName?.charAt(0)?.toUpperCase();
    }
    name = `${user.firstName} ${surnameInitial}`;
  }

  return name;
};

const ProposedTipIntent = observer(() => {
  const classes = useStyles();

  const [proposedTip, setProposedTip] = useState<string>('');
  const [customTip, setCustomTip] = useState<string>('');
  const [tipLevel, setTipLevel] = useState<TipLevel>(TipLevel.CUSTOM);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);

  const lastTip = appStore.currentSubrouteIndex && appStore.currentSubrouteIndex >= appStore.users!.length;

  const getChosenTip = () => (customTip !== '' ? customTip : proposedTip);

  const goToNextTip = async (tipAmount) => {
    try {
      tipStore.addTip(tipAmount, tipLevel).then();
      setCustomTip('');
      setProposedTip('');
      setTipLevel(TipLevel.CUSTOM);

      appStore.goToNextScreen();
    } catch (e) {
      console.log(e);
    }
  };

  const buttonDisabled = (!proposedTip || proposedTip === '') && customTip === '';

  const onClickNext = () => {
    if (customTip && customTip !== '' && Number.parseFloat(customTip) >= 100) {
      setConfirmationOpen(true);
    } else {
      goToNextTip(getChosenTip());
    }
  };

  const button = () => {
    if (lastTip) {
      return (
        <TButtonPrimary disabled={buttonDisabled} fullWidth onPress={onClickNext}>
          Tip away
        </TButtonPrimary>
      );
    } else {
      return (
        <TButtonPrimary disabled={buttonDisabled} fullWidth onPress={onClickNext}>
          Next
        </TButtonPrimary>
      );
    }
  };

  let user: User | undefined;
  if (appStore.users) {
    user = appStore.users[appStore.currentSubrouteIndex! - 1];
  }

  const currentTip = appStore.intentPayload?.tips[appStore.currentSubrouteIndex! - 1];
  const tipProposals = currentTip?.proposals;

  if (appStore.loadingData) {
    return (
      <Box
        alignItems="center"
        justifyContent="center"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          width: '100vw',
          height: '100vh',
        }}
      >
        <TCircularProgress />
      </Box>
    );
  }

  return (
    <MainLayout button={button()}>
      {user && tipProposals && (
        <Box justifyContent="center" alignItems="center">
          <Card style={{ padding: 0 }}>
            <CardContent className={classes.userCard}>
              <div style={{ flex: 3, width: '100%' }}>
                <Avatar alt={user.firstName} src={user.avatar} className={classes.avatar} variant="square" />
              </div>
              <div style={{ flex: 1, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TText style={{ fontWeight: 'bold', paddingTop: '1em' }}>{getName(user)}</TText>
              </div>
            </CardContent>
          </Card>

          <SpacerV size="large" />

          <TText>{appStore?.locationSettings?.tipInstructionText || 'Select tip amount'}</TText>
          <SpacerV size="small" />
          <div className={classes.tipOptionsRow}>
            <TipProposalCard
              tipProposals={tipProposals!}
              proposalLevel={TipLevel.LOW}
              tip={proposedTip}
              setTip={setProposedTip}
              customTip={customTip}
              setCustomTip={setCustomTip}
              setTipLevel={setTipLevel}
              goToNextTip={goToNextTip}
            />
            <TipProposalCard
              tipProposals={tipProposals!}
              proposalLevel={TipLevel.MEDIUM}
              tip={proposedTip}
              setTip={setProposedTip}
              customTip={customTip}
              setCustomTip={setCustomTip}
              setTipLevel={setTipLevel}
              goToNextTip={goToNextTip}
            />
            <TipProposalCard
              tipProposals={tipProposals!}
              proposalLevel={TipLevel.HIGH}
              tip={proposedTip}
              setTip={setProposedTip}
              customTip={customTip}
              setCustomTip={setCustomTip}
              setTipLevel={setTipLevel}
              goToNextTip={goToNextTip}
            />
          </div>

          <SpacerV size="large" />
          <TText>You don't like our options?</TText>
          <TTextField
            label="Custom Tip"
            type="tel"
            fullWidth
            value={customTip}
            onChange={(e) => {
              const { value } = e.target;
              if (isNormalNumber(value) || isEmpty(value)) {
                setCustomTip(value);

                if (value !== '') {
                  setProposedTip('');
                }
              }
            }}
          />
        </Box>
      )}

      <ConfirmationDialog
        title="Wow, a generous tip"
        text={`Are you sure you wanted to tip $${customTip}?`}
        open={confirmationOpen}
        onConfirm={() => {
          goToNextTip(getChosenTip());
        }}
        onCancel={() => {
          setConfirmationOpen(false);
        }}
      />
    </MainLayout>
  );
});

export default ProposedTipIntent;
