import React from 'react';
import Typography from '@material-ui/core/Typography';
import { TText } from './TText';

interface Props {
  value: string;
}

const Description: React.StatelessComponent<Props> = (props) => {
  const { value } = props;

  return (
    <TText variant="body1" style={{ textAlign: 'center' }}>
      {value}
    </TText>
  );
};

export default Description;
