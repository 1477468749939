import React from 'react';
import { Divider, MobileStepper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { appStore } from '../../stores/AppStore';
import { createMainTheme } from '../../theme';

const useStyles = makeStyles(() => {
  const theme = createMainTheme(appStore.theme);

  return {
    root: {
      position: 'relative',
      width: '100%',
      padding: '20px 0px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
      alignSelf: 'center',
      justifySelf: 'center',
    },
    stepper: {
      position: 'absolute',
      top: -2,
      alignSelf: 'center',
      justifySelf: 'center',
    },
    dots: {
      align: 'center',
      textAlign: 'center',
    },
    dot: {
      margin: 10,
    },
    dotActive: {
      backgroundColor: theme.palette.primary.main,
    },
    stepperSide: {
      borderTop: '1px solid #999999',
    },
  };
});

const Steps = observer(() => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Divider />
      <MobileStepper
        variant="dots"
        steps={appStore.flow.length}
        position="static"
        activeStep={appStore.currentStep()}
        className={classes.stepper}
        classes={{
          dots: classes.dots,
          dot: classes.dot,
          dotActive: classes.dotActive,
        }}
        nextButton=""
        backButton=""
      />
    </div>
  );
});

export default Steps;
