import React from 'react';
import { WithStyles, withStyles, createStyles, Theme, alpha } from '@material-ui/core';
import { TText } from './TText';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      color: alpha(theme.palette.text.secondary, 0.5),
      fontWeight: 900,
    },
  });

interface Props extends WithStyles<typeof styles> {
  value: string;
}
const ItemTitle: React.StatelessComponent<Props> = ({ value, classes }: Props) => (
  <>
    <TText variant="h6" className={classes.title}>
      {value}
    </TText>
  </>
);

export default withStyles(styles)(ItemTitle);
