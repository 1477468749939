import { alpha } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { ThemeSettings } from './models/LocationSettings';

export const createMainTheme = (
  themeSettings?: ThemeSettings,
  secondary: { main: string } = {
    main: '#FFC768',
  },
) => {
  const primaryColor = themeSettings?.primaryColor || '#1AD9B6';
  const textColor = themeSettings?.textColor || '#200E32';
  const contrastColor = themeSettings?.contrastColor || '#fff';

  const secondaryText = alpha(textColor, 0.6);

  return createMuiTheme({
    palette: {
      primary: {
        main: primaryColor,
        contrastText: contrastColor,
      },
      secondary,
      text: {
        primary: textColor,
        secondary: secondaryText,
      },
    },
    typography: {
      fontFamily: ['EuclidCircularB', 'Quicksand', 'Roboto'].join(','),
      h3: {
        fontSize: 52,
        fontWeight: 700,
      },
      h4: {
        fontSize: 21,
        fontWeight: 700,
      },
      h5: {
        fontSize: 18,
      },
      h6: {
        fontSize: 18,
        fontWeight: 900,
      },
    },
    shape: {
      borderRadius: 16,
    },
  });
};
