import { observer } from 'mobx-react';
import { Card, Typography } from '@material-ui/core';
import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import TButtonPrimary from '../../components/common/TButtonPrimary';
import MainLayout from '../../layout/hospitality/MainLayout';
import { appStore } from '../../stores/AppStore';
import { TText } from '../../components/common/TText';

const useStyles = makeStyles((theme) => ({
  checkCircle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#21807B',
    paddingTop: 32,
    paddingBottom: 32,
  },
  thankYouText: {
    fontSize: 24,
  },
}));

const Success = observer(() => {
  const classes = useStyles();

  const button = (
    <TButtonPrimary fullWidth onPress={() => appStore.resetValues()}>
      Tip again
    </TButtonPrimary>
  );

  return (
    <MainLayout button={appStore.code ? button : undefined}>
      <Card>
        <div className={classes.checkCircle}>
          <CheckCircleIcon style={{ fontSize: '16rem' }} color="primary" />
          <TText className={classes.thankYouText}>Thank you</TText>
        </div>
      </Card>
    </MainLayout>
  );
});

export default Success;
