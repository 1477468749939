import React, { useState } from 'react';
import { alpha, AppBar, Box, Container, Divider, IconButton, InputAdornment, Link, Toolbar } from '@material-ui/core';
import SpacerV from 'components/common/SpacerV';
import logo from 'images/logo.svg';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Title from 'components/common/Title';
import SearchIcon from '@material-ui/icons/Search';
import TTextField from 'components/common/TTextField';
import { Clear } from '@material-ui/icons';
import Steps from 'containers/common/Steps';
import { TippyScreen } from '../../models/TippyScreen';
import { appStore } from '../../stores/AppStore';
import { createMainTheme } from '../../theme';
import { TText } from '../../components/common/TText';
import ItemsSearch from './ItemsSearch';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100vh',
    padding: theme.spacing(2),
  },
  header: {
    flex: '0 1 auto',
  },
  content: {
    flex: '1 1 auto',
    backgroundColor: theme.palette.background.default,
  },
  footer: {
    flex: '0 1 40px',
    textAlign: 'center',
    paddingBottom: 60,
  },
  appBar: {
    backgroundColor: 'transparent',
    height: '5.5vh',
  },
  navigation: {
    textAlign: 'center',
  },
  containerLogo: {
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',

    display: 'flex',
    width: '15vh',
    height: '15vh',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1.5rem',
    borderRadius: '100px',
    backgroundColor: '#ff00000a',
    overflow: 'hidden',
  },
  locationLogo: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    position: 'relative',
  },
  icon: {
    color: alpha(theme.palette.text.secondary, 0.5),
    width: '4vh',
    height: '4vh',
  },
  iconBox: {
    display: 'flex',
    padding: '0.5vh',
    marginLeft: 'auto',
  },
  clearIcon: {
    color: theme.palette.grey[500],
  },
  '@keyframes searchEfect': {
    '0%': {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 0,
    },
    '100%': {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    },
  },
  search: {
    animation: `$searchEfect 500ms ${theme.transitions.easing.easeInOut}`,
  },
}));

interface Props {
  children: React.ReactNode;
  button?: React.ReactElement;
  disableSteps?: boolean;
  showToS?: boolean;
}

const ItemsLayout = observer(({ children, button, disableSteps, showToS }: Props) => {
  const classes = useStyles();
  const theme = createMainTheme(appStore.theme);
  const [openSearchItems, setOpenSearch] = useState(false);

  const closeSearch = () => {
    setOpenSearch(false);
  };

  const showBackButton =
    (appStore.currentStep() > 0 || appStore.uid) &&
    appStore.currentScreen !== TippyScreen.SUCCESS &&
    appStore.currentScreen !== TippyScreen.REVIEW;

  const showTippyLogo = !appStore.payload?.logo && appStore.theme === undefined;

  const showItemsSearch = appStore.currentScreenEquals([TippyScreen.STATION, TippyScreen.GROUP, TippyScreen.LOCATION]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <AppBar className={classes.appBar} elevation={0} position="static">
          <Toolbar className={classes.toolbar}>
            {showBackButton && !openSearchItems && (
              <IconButton onClick={() => appStore.goToPrevScreen()}>
                <KeyboardBackspaceIcon style={{ color: theme.palette.text.primary }} />
              </IconButton>
            )}
            {!openSearchItems && (
              <Box className={classes.containerLogo}>
                {appStore.payload?.logo ? (
                  <img src={appStore.payload?.logo} className={classes.locationLogo} alt="location logo" />
                ) : (
                  <img src={logo} alt="tippy logo" height={48} />
                )}
              </Box>
            )}
          </Toolbar>
        </AppBar>
        <div className={classes.navigation} style={{ marginTop: appStore.payload?.logo ? '3.5rem' : '5rem' }}>
          {appStore.payload?.name && (
            <>
              {appStore.payload.logo && <SpacerV />}
              <Title value={appStore.payload!.name} subtitle={appStore.selectedLocation?.address} />{' '}
            </>
          )}
          <SpacerV size="small" />
          {!disableSteps && <Steps />}
          <SpacerV size="small" />
          {/* <SpacerV />
          <Divider />
          <SpacerV /> */}
        </div>
      </div>

      <div className={classes.content}>
        <Container style={{ height: '100%' }} maxWidth="xs">
          <ItemsSearch
            openSearchItems={openSearchItems}
            closeSearch={closeSearch}
            showItemsSearch={showItemsSearch}
            setOpenSearch={setOpenSearch}
            small
          />
          <div>{children}</div>
        </Container>
      </div>

      <div className={classes.footer}>
        <Container maxWidth="xs">
          {button}
          {showToS && (
            <div>
              <SpacerV size="small" />
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <TText variant="body2">By submitting the payment you agree to</TText>
                <Link
                  href="https://www.meettippy.com/enterprise-services-agreement/"
                  variant="body2"
                  target="_blank"
                  style={{ color: theme.palette.primary.main }}
                >
                  &nbsp;Terms of Service
                </Link>
              </div>
            </div>
          )}
          <SpacerV size="small" />
          <Link href="mailto:support@meettippy.com" style={{ color: theme.palette.primary.main }}>
            support@meettippy.com
          </Link>
        </Container>
      </div>
    </div>
  );
});

export default ItemsLayout;
