import React from 'react';
import { WithStyles, withStyles, createStyles, Theme, Button, ButtonProps } from '@material-ui/core';
import { darken, makeStyles } from '@material-ui/core/styles';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { createMainTheme } from '../../theme';
import { appStore } from '../../stores';

const useStyles = makeStyles(() => {
  const theme = createMainTheme(appStore.theme);

  return {
    root: {
      fontWeight: 700,
      background: theme.palette.primary.main,
      '&:hover': {
        background: darken(theme.palette.primary.main, 0.5),
      },
      boxShaddow: '0 none',
      borderRadius: 16,
      fontSize: 20,
      textTransform: 'none',
      padding: '10px 30px',
    },
  };
});

const TButtonPrimary = observer(({ children, onPress, ...rest }) => {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      className={classes.root}
      size="large"
      variant="contained"
      color="primary"
      onClick={onPress}
      {...rest}
    >
      {children}
    </Button>
  );
});

export default TButtonPrimary;
