/* eslint-disable consistent-return */
import axios, { AxiosError } from 'axios';
import Payload from 'models/Payload';
import getFingerprint from 'utils';
import config from '../config';
import LocationInfo from '../models/LocationInfo';
import AppStore from '../stores/AppStore';

const api = {
  v1: axios.create({ baseURL: `${config.api.core}/v1` }),
  v2: axios.create({ baseURL: `${config.api.core}/v2` }),
  v3: axios.create({ baseURL: `${config.api.core}/v3` }),
  base: axios.create(),
};

api.v1.interceptors.request.use((_config) => {
  _config.headers['X-Fingerprint'] = getFingerprint();
  return _config;
});

api.v2.interceptors.request.use((_config) => {
  _config.headers['X-Fingerprint'] = getFingerprint();
  return _config;
});

api.v3.interceptors.request.use((_config) => {
  _config.headers['X-Fingerprint'] = getFingerprint();
  return _config;
});

api.base.interceptors.request.use((_config) => {
  _config.headers['X-Fingerprint'] = getFingerprint();
  return _config;
});

export class CoreAPI {
  public static async exchangeCode(url: string, appStore: AppStore) {
    try {
      const res = await api.base.get(url);

      if (res.status === 200) {
        return res.data.data;
      } else {
        appStore.goToNotFoundScreen();
      }
    } catch (e) {
      appStore.goToNotFoundScreen();
      throw e;
    }
  }

  public static async getLocationV3(uid: string, appStore: AppStore): Promise<Payload> {
    try {
      return await api.v3
        .get(`/web/locations/${uid}`)
        .then((res) => {
          if (res.status === 200) {
            return res.data.data;
          }
        })
        .catch((error: AxiosError) => {
          const errorRes = error.response?.data.error;
          appStore.showAlert(errorRes.code, errorRes.message, 'error');
        });
    } catch (e) {
      throw e;
    }
  }

  public static async getLocationUser(locationUid: string, userUid: string) {
    const res = await api.v3.get(`web/locations/${locationUid}/users/${userUid}`);

    return res.data.data;
  }

  public static async getLocationSettings(locationId: string) {
    const res = await api.v2.get(`/web/locations/${locationId}/settings`);

    return res.data.data;
  }

  public static async getStationSettings(uid: string) {
    const res = await api.v2.get(`/web/stations/${uid}/settings`);

    return res.data.data;
  }
}
