import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import 'App.css';

import { Routes } from './routes';
import { appStore } from './stores';
import { createMainTheme } from './theme';

function App() {
  return (
    <MuiThemeProvider theme={createMainTheme(appStore.theme)}>
      <CssBaseline />
      <Router>
        <Routes />
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
