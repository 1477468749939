import React from 'react';
import { Alert } from '@material-ui/lab';
import { Dialog, Typography } from '@material-ui/core';
import { Severity } from '../models/Severity';
import SpacerV from './common/SpacerV';
import { TText } from './common/TText';

interface AlertPopupProps {
  title: string;
  text: string;
  open: boolean;
  severity: Severity;
  onClose: () => void;
}

const AlertPopup = ({ title, text, open, onClose, severity }: AlertPopupProps) => (
  <Dialog onClose={onClose} open={open}>
    <Alert
      onClose={onClose}
      severity={severity}
      style={{ width: '100%', alignContent: 'center', alignItems: 'center' }}
    >
      <TText style={{ fontSize: 18, textAlign: 'center' }}>{title}</TText>
      <SpacerV size="small" />
      <TText style={{ fontSize: 14, textAlign: 'center' }}>{text}</TText>
    </Alert>
  </Dialog>
);

export default AlertPopup;
