import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import MainLayout from 'layout/hospitality/MainLayout';
import SpacerV from 'components/common/SpacerV';
import Description from 'components/common/Description';
import TTextField from 'components/common/TTextField';
import TButtonPrimary from 'components/common/TButtonPrimary';
import Item from 'components/common/Item';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { TippingMode } from '../../models/TippingMode';
import { isNormalNumber } from '../../utils';
import { appStore } from '../../stores/AppStore';
import { tipStore } from '../../stores/TipStore';
import PoolInfo from '../../models/PoolInfo';
import AlertPopup from '../../components/AlertPopup';
import { TCircularProgress } from '../../components/common/TCircularProgress';
import { ConfirmationDialog } from '../../components/ConfirmationDialog';

export const customTipText = 'Have a custom tip amount in mind?';

const ProposedTipHospitality = observer(() => {
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);

  let poolIdx = 0;
  if (appStore.selectedPools !== undefined && appStore.selectedPools.length > 0) {
    poolIdx = appStore.selectedPools.length - 1;
  }

  let selectedPool: PoolInfo | undefined;
  if (appStore.selectedPools) {
    selectedPool = appStore.selectedPools![poolIdx];
  }

  let proposedTip = selectedPool?.lowAmount!;
  if (selectedPool?.tippingMode === TippingMode.PER_NIGHT) {
    proposedTip *= appStore.selectedNumberOfNights[poolIdx]!;
  }

  const [customTip, setCustomTip] = useState<string>('');

  const goToPayment = async (tip: string) => {
    await tipStore.addTip(tip);

    appStore.goToNextScreen();
  };

  const onClickTipProposed = () => {
    goToPayment(proposedTip.toString()).then();
  };

  const onClickTipCustom = () => {
    if (customTip && customTip !== '' && Number.parseFloat(customTip) >= 100) {
      setConfirmationOpen(true);
    } else {
      goToPayment(customTip || proposedTip.toString()).then();
    }
  };

  const button = (
    <TButtonPrimary disabled={!customTip || customTip === ''} fullWidth onPress={onClickTipCustom}>
      Next
    </TButtonPrimary>
  );

  if (appStore.loadingData) {
    return (
      <Box
        alignItems="center"
        justifyContent="center"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          width: '100vw',
          height: '100vh',
        }}
      >
        <TCircularProgress />
      </Box>
    );
  }

  return (
    <MainLayout button={button}>
      <Box justifyContent="center" alignItems="center">
        {selectedPool?.tippingMode === TippingMode.PER_NIGHT && (
          <Description
            value={`90% of guests usually tip $${proposedTip} for ${appStore.selectedNumberOfNights[poolIdx]} nights`}
          />
        )}
        {selectedPool?.tippingMode !== TippingMode.PER_NIGHT && (
          <Description value={`90% of guests usually tip $${proposedTip}`} />
        )}

        <SpacerV />
        <Item label={`Go ahead, tip $${proposedTip}`} onClick={onClickTipProposed} />
        <SpacerV size="large" />
        <Description value={customTipText} />
        <TTextField
          label="Custom Tip"
          type="tel"
          fullWidth
          value={customTip}
          onChange={(e) => {
            const { value } = e.target;
            if (isNormalNumber(value) || isEmpty(value)) {
              setCustomTip(value);
            }
          }}
        />
      </Box>

      <ConfirmationDialog
        title="Wow, a generous tip"
        text={`Are you sure you wanted to tip $${customTip}?`}
        open={confirmationOpen}
        onConfirm={() => {
          goToPayment(customTip);
        }}
        onCancel={() => {
          setConfirmationOpen(false);
        }}
      />
    </MainLayout>
  );
});

export default ProposedTipHospitality;
