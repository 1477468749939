/* eslint-disable react/jsx-no-duplicate-props */
import React, { CSSProperties } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { createMainTheme } from '../../theme';
import { appStore } from '../../stores';

const useStyles = makeStyles(() => {
  const theme = createMainTheme(appStore.theme);

  return {
    root: {
      border: '1px solid #E0E0E0',
      overflow: 'hidden',
      borderRadius: 16,
      fontWeight: 600,
      backgroundColor: theme.palette.common.white,
      padding: '0px 0px 0px',
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
      '&$focused': {
        backgroundColor: theme.palette.common.white,
      },
      multiline: {
        padding: '10px 10px 0px',
        lineHeight: 1.5,
      },
    },
    focused: {
      backgroundColor: theme.palette.common.white,
    },
    multiline: {
      padding: '10px 10px 0px',
      lineHeight: 1.5,
    },
  };
});

interface Props {
  centered?: boolean;
  large?: boolean;
}

const TTextField: React.StatelessComponent<Props & TextFieldProps> = (props) => {
  const classes = useStyles();
  const theme = createMainTheme(appStore.theme);

  const styles: CSSProperties = {};
  if (props.centered) {
    styles.textAlign = 'center';
  }

  if (props.large) {
    styles.fontSize = 36;
  }

  return (
    <TextField
      variant="filled"
      inputProps={{ style: styles }}
      margin="normal"
      InputProps={{ classes, disableUnderline: true, inputProps: { min: 0 } } as Partial<OutlinedInputProps>}
      InputLabelProps={{
        style: { color: theme.palette.primary.main },
      }}
      {...props}
    />
  );
};

export default TTextField;
