import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { StarBorder } from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';
import MainLayout from 'layout/hospitality/MainLayout';
import SpacerV from 'components/common/SpacerV';
import Description from 'components/common/Description';
import TTextField from 'components/common/TTextField';
import TButtonPrimary from 'components/common/TButtonPrimary';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { appStore } from '../../stores/AppStore';

const useStyles = makeStyles((theme) => ({
  icon: {
    margin: '0px 10px',
  },
  iconFilled: {
    color: theme.palette.primary.main,
  },
}));

const Review = observer(() => {
  const classes = useStyles();

  const [review, setReview] = useState<number | null>(null);
  const [note, setNote] = useState('');

  const button = (
    <TButtonPrimary
      disabled={!review}
      fullWidth
      onPress={() => {
        appStore.postReview(review!, note).then();
      }}
    >
      Submit
    </TButtonPrimary>
  );

  return (
    <MainLayout button={button}>
      <Box justifyContent="center" alignItems="center">
        <Description value="Rate your service" />
        <SpacerV />
        <Box mb={3} borderColor="transparent" textAlign="center">
          <Rating
            name="service-rating"
            value={review}
            onChange={(event, newValue) => {
              setReview(newValue);
            }}
            size="large"
            classes={{
              icon: classes.icon,
              iconFilled: classes.iconFilled,
              iconHover: classes.iconFilled,
            }}
            emptyIcon={<StarBorder fontSize="inherit" />}
          />
        </Box>
        <SpacerV />
        <Description value="Would you like to leave a note?" />
        <TTextField
          placeholder="Note (Optional)"
          fullWidth
          multiline
          rows={5}
          value={note}
          onChange={(event) => {
            setNote(event.target.value);
          }}
        />
      </Box>
    </MainLayout>
  );
});

export default Review;
