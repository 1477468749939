import PoolInfo from './PoolInfo';
import { User } from './User';

export interface Group {
  type: ItemType.GROUP;
  uid: string;
  name: string;
  items: Item[];
}

export enum ItemType {
  USER = 'user',
  POOL = 'pool',
  GROUP = 'group',
  //   LOCATION = 'location',
}

export interface IUser {
  type: ItemType.USER;
  name: string;
  user: User;
}

export interface IPool {
  type: ItemType.POOL;
  name: string;
  pool: PoolInfo;
}

export interface IGroup {
  type: ItemType.GROUP;
  name: string;
  group: Group;
}

export interface IStation {
  items: StationItems[];
  locationUid: string;
  name: string;
  uid: string;
}

export type StationItems = IUser | IPool | IGroup;
export type GroupItem = IUser | IPool;
export type LocationItem = IUser | IPool;

export type Item = IUser | IGroup | IPool;

export enum TippyItems {
  STATION = 'station',
  LOCATIONS = 'locations',
  LOCATION = 'location',
  USERS = 'users',
  POOLS = 'pools',
  GROUP = 'group',
}
