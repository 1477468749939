/* eslint-disable @typescript-eslint/no-use-before-define */
import { makeAutoObservable, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import _ from 'lodash';
import { KioskTip } from 'models/TipIntentPayload';
import { TipLevel } from '../models/TipLevel';
import { TipsAPI } from '../api/TipsAPI';
import { appStore } from './AppStore';

export default class TipStore {
  initialized = false;

  selectedTips?: string[];
  selectedTipLevels?: TipLevel[];
  feeResponses?: any[];

  review?: string;

  extraOptionalTipChecked?: boolean = true;

  refreshingFee?: boolean = false;

  constructor() {
    makeAutoObservable(this);

    makePersistable(this, {
      name: 'TipStore',
      properties: [
        'selectedTips',
        'selectedTipLevels',
        'feeResponses',
        'review',
        'extraOptionalTipChecked',
        'refreshingFee',
      ],

      storage: window.sessionStorage,
    }).then(() => {
      this.initialized = true;
    });
  }

  resetValues() {
    this.selectedTips = undefined;
    this.selectedTipLevels = undefined;
    this.feeResponses = undefined;

    this.review = undefined;

    this.extraOptionalTipChecked = true;
    this.refreshingFee = false;
  }

  totalSum() {
    const totals = this.feeResponses?.map((resp) => parseFloat(resp.total));

    return _.sum(totals);
  }

  feesSum() {
    const fees = this.feeResponses?.map((resp) => parseFloat(resp.fee));
    return _.sum(fees);
  }

  async addTip(tip: string | KioskTip, tipLevel?: TipLevel) {
    appStore.loadingData = true;

    let mode = 'gross';
    const transparency = appStore.locationSettings?.transparency;
    if (transparency === 'full' || transparency === 'optional' || appStore.intentPayload?.mode === 'net') {
      mode = 'net';
    }

    try {
      let feeResponse;
      if (typeof tip === 'string') {
        feeResponse = await TipsAPI.calculateFees(tip.toString(), mode);
      }

      if (!tipStore.selectedTips) {
        tipStore.selectedTips = [];
      }

      if (!tipStore.feeResponses) {
        tipStore.feeResponses = [];
      }

      if (!tipStore.selectedTipLevels) {
        tipStore.selectedTipLevels = [];
      }

      if (typeof tip === 'object') {
        const { fee, total, tipAmount } = tip;
        tipStore.selectedTips!.push(tipAmount!);
        tipStore.selectedTipLevels!.push(TipLevel.CUSTOM);
        tipStore.feeResponses!.push({ fee, tipAmount, total });

        tipStore.feeResponses = toJS(tipStore.feeResponses); // to refresh mobx
      } else {
        tipStore.selectedTips!.push(tip);
        tipStore.selectedTipLevels!.push(tipLevel || TipLevel.CUSTOM);
        tipStore.feeResponses!.push(feeResponse);

        tipStore.feeResponses = toJS(tipStore.feeResponses); // to refresh mobx
      }
    } catch (e) {
      console.log(e);
    }

    appStore.loadingData = false;
  }

  async removeTipAt(tipIdx: number) {
    appStore.loadingData = true;

    try {
      tipStore.selectedTips!.splice(tipIdx, 1);
      tipStore.selectedTipLevels!.splice(tipIdx, 1);
      tipStore.feeResponses!.splice(tipIdx, 1);
    } catch (e) {
      console.log(e);
    }

    appStore.loadingData = false;
  }

  async recalculateFees() {
    const mode = this.extraOptionalTipChecked ? 'net' : 'gross';

    try {
      this.refreshingFee = true;
      let newResponses: any[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const tip of tipStore.selectedTips!) {
        const feeResponse = await TipsAPI.calculateFees(tip.toString(), mode);
        newResponses = [...newResponses, feeResponse];
      }

      this.feeResponses = newResponses;
      this.refreshingFee = false;
    } catch (e) {
      console.log(e);
    }
  }

  async removeLastTip() {
    tipStore.selectedTips?.pop();
    tipStore.selectedTipLevels?.pop();
    tipStore.feeResponses?.pop();
  }
}

export const tipStore = new TipStore();
