import { Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createMainTheme } from '../../theme';
import { appStore } from '../../stores';

const useStyles = makeStyles(() => {
  const theme = createMainTheme(appStore.theme);

  return {
    text: {
      color: theme.palette.text.primary,
    },
  };
});

export const TText = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.text} {...rest}>
      {children}
    </Typography>
  );
};
