/* eslint-disable no-return-assign, consistent-return */
import type { Payments } from '@square/web-payments-sdk-types';

class SquarePayments {
  public static setLoader = (onLoad: (loaded: boolean) => void) => {
    const existingScript = document.getElementById('webPayment');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = process.env.REACT_APP_SQUARE_SCRIPT_URL!;
      script.id = 'webPayment';
      document.body.appendChild(script);

      return (script.onload = async () => {
        onLoad(true);
      });
    }
  };

  public static load = async (onLoad: (p: Payments) => void) => {
    if (!window?.Square) {
      console.error('Square.js failed to load properly');
      return;
    }

    const squareAppId = process.env.REACT_APP_SQUARE_APPLICATION_ID;
    const squareLocationId = process.env.REACT_APP_SQUARE_LOCATION_ID;

    // console.log(squareAppId);
    // console.log(squareLocationId);

    const payments = (await window.Square?.payments(squareAppId!, squareLocationId)) as Payments;
    onLoad(payments);
  };

  public static tokenize = async (paymentMethod) => {
    const tokenResult = await paymentMethod.tokenize();
    return tokenResult;
  };
}

export default SquarePayments;
